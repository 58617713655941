import { notification } from 'antd';
import Axios from 'axios';
import Cookies from 'js-cookie';
const access_token = Cookies.get('access_token');
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;
//-------------------------------------------------------------------
const imageUploadSave = async (vfile, url) => {
  console.log('Inside Helper imageUploadSave');
  var image;
  // console.log(vfile);
  const formData = new FormData();
  [...vfile].map(file => {
    formData.append('photo', file);
  });
  //for single file working below code-----------------
  //   const formData = new FormData();
  //   formData.append('photo', file);
  //-----------------------------------------------------

  //formData.append('photoName', fileName);
  // console.log(formData);

  var ImageURL = [
    domainpath + '/attachment/product',
    domainpath + '/images/storefeatured',
    domainpath + '/images/category',
    domainpath + '/images/storetype',
    domainpath + '/images/bannerHome',
  ];

  async function vuploadphotofunc(url, formData) {
    var findIndex = ImageURL.indexOf(url);
    await Axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        token: 'Bearer ' + access_token,
        //token: 'Bearer hjskdhskjdhsjkdhskjdhskjdhskdhskjdhsdjksjhdsjkdsdks',
      },
    })
      .then(response => {
        if (response.status === 200 && response.data.success === 'Success') {
          console.log(response.data.url, 'insideeeee');
          // image = response.data.url;
          // if(ImageURL.includes(url)){
          if (findIndex >= 0) {
            image = response.data;
          } else {
            image = response.data.url;
          }

          //  objDataFinal[index][table_fieldName] = image;
          // setImageURL(response.data.url);
          notification.success({
            message: 'Image Added Successfully',
          });
          setTimeout(() => {
            notification.destroy();
          }, 3000);
          //return image;
        } else {
          notification.error({
            message: 'Error in Image Upload!!',
          });
          // return 0;
        }
      })
      .catch(error => {
        notification.error({
          message: error.response.data.message,
        });
        //return 0;
      });
  }
  const hh = await vuploadphotofunc(url, formData);
  // console.log(image);
  // console.log('Ppp');
  return image;
};
//-----------------------------------------------------------------
//module.exports = { imageUploadSave };

export default imageUploadSave;
