import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
const page_path = '../../container/pages/';
// const Users = lazy(() => import('../../container/pages/Users'));
const AddUser = lazy(() => import('../../container/pages/AddUsers'));
const EditUser = lazy(() => import('../../container/pages/EditUsers'));
const ViewUser = lazy(() => import('../../container/pages/ViewUsers'));
const UsersList = lazy(() => import('../../container/pages/UsersList'));
//
const UsersRole = lazy(() => import('../../container/pages/UsersRole'));
const AddUsersRole = lazy(() => import('../../container/pages/AddUsersRole'));
const ViewUsersRole = lazy(() => import('../../container/pages/ViewUsersRole'));
const EditUsersRole = lazy(() => import('../../container/pages/EditUsersRole'));
const UsersPermission = lazy(() => import('../../container/pages/UsersPermission'));
const UsersRolePermissions = lazy(() => import('../../container/pages/UsersRolePermissions'));
// const AddNewStoreUser = lazy(() => import('../../container/pages/AddStoreUserList'));
const AddCustomer = lazy(() => import('../../container/pages/AddCustomer'));
const ViewCustomer = lazy(() => import('../../container/pages/ViewCustomer'));
const EditCustomer = lazy(() => import('../../container/pages/EditCustomer'));

const PartnerList = lazy(() => import('../../container/ecommerce/PartnerList'));
const AddPartner = lazy(() => import('../../container/pages/AddPartner'));
const ViewPartner = lazy(() => import('../../container/pages/ViewPartner'));
const EditPartner = lazy(() => import('../../container/pages/EditPartner'));

const PartnersRoles = lazy(() => import('../../container/ecommerce/PartnersRoles'));
const AddPartnerRole = lazy(() => import('../../container/pages/AddPartnerRole'));
const ViewPartnersRole = lazy(() => import('../../container/pages/ViewPartnersRole'));
const EditPartnersRole = lazy(() => import('../../container/pages/EditPartnersRole'));

const Demo = lazy(() => import('../../container/pages/Demo'));
// const AddUsersDemo = lazy(() => import('../../container/pages/AddUsersDemo'));

// const DataTable = lazy(() => import('../../container/pages/UserListDataTable'));
// const RolesDataTable = lazy(() => import('../../container/pages/UserRolesDataTable'));
// const ModulesDataTable = lazy(() => import('../../container/pages/ModulesListTable'));
// const Team = lazy(() => import('../../container/pages/Team'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* 1 */}
      <Route path={`${path}/add-user`} component={AddUser} />
      <Route path={`${path}/edit-user/:id`} component={EditUser} />
      <Route path={`${path}/view-user/:id`} component={ViewUser} />
      <Route path={`${path}/users`} component={UsersList} />
      {/* 2 */}
      <Route path={`${path}/roles`} component={UsersRole} />
      <Route path={`${path}/add-users-role`} component={AddUsersRole} />
      <Route path={`${path}/view-users-role/:id`} component={ViewUsersRole} />
      <Route path={`${path}/edit-users-role/:id`} component={EditUsersRole} />
      {/* <Route path={`${path}/addusers-demo`} component={AddUsersDemo} /> */}

      {/* <Route path={`${path}/users-permission`} component={UsersPermission} />
      <Route path={`${path}/users-role-permissions`} component={UsersRolePermissions} /> */}

      <Route path={`${path}/add-customer`} component={AddCustomer} />
      <Route path={`${path}/view-customer/:id`} component={ViewCustomer} />
      <Route path={`${path}/edit-customer/:id`} component={EditCustomer} />

      <Route path={`${path}/add-partner`} component={AddPartner} />
      <Route path={`${path}/view-partner/:id`} component={ViewPartner} />
      <Route path={`${path}/edit-partner/:id`} component={EditPartner} />
      <Route path={`${path}/partner-list`} component={PartnerList} />

      <Route path={`${path}/add-partner-role`} component={AddPartnerRole} />
      <Route path={`${path}/view-partner-role/:id`} component={ViewPartnersRole} />
      <Route path={`${path}/edit-partner-role/:id`} component={EditPartnersRole} />
      <Route path={`${path}/partners-roles`} component={PartnersRoles} />

      {/* <Route path={`${path}/add-user-new`} component={AddNewStoreUser} /> */}

      {/*<Route path={`${path}/dataTable`} component={DataTable} />
      <Route path={`${path}/rolesDataTable`} component={RolesDataTable} />
      <Route path={`${path}/modulesDataTable`} component={ModulesDataTable} />
      <Route path={`${path}/team`} component={Team} />
      <Route path={`${path}`} component={Users} /> */}
    </Switch>
  );
};

export default PagesRoute;
