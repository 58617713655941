import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCSGFoGRQFTBwORFQzP-ih1K3B_eyuh6aM',
  authDomain: 'alien-container-374409.firebaseapp.com',
  projectId: 'alien-container-374409',
  storageBucket: 'alien-container-374409.appspot.com',
  messagingSenderId: '454894084873',
  appId: '1:454894084873:web:16278ee092b09f8a1629d8',
  measurementId: 'G-E2B4JNP1CJ',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  // Notification.requestPermission().then(permission => {
  // if (permission === 'granted') {
  // console.log('Notification permission granted.');
  // Initialize Firebase
  return getToken(messaging, {
    vapidKey: `BGqb6X-_qGFTD0vPhb8D9u7N2ocQIJIUjwRKOUoHoza5mzGcVj8Rcz89TQNdEo-yr2gaOeIamU8TLBgy-PkDC6k`,
  })
    .then(currentToken => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
    });
  // } else {
  //   console.log('Do not have permission!');
  // }
  //  });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
