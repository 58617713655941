import React from 'react';
import { MaintananceWrapper } from './style';
import { Main } from '../styled';
import Heading from '../../components/heading/heading';
import logo from '../../../src/static/img/ezmoov_black.png';
var ModuleName = 'MAINTENANCE';

const Maintenance = () => {
  return (
    <Main className="maintenance_main">
      <MaintananceWrapper className="maintenance_main">
        <img src={logo} alt="maintenance" className="maintenance_logo" />
        <h1 className="heading">
          Lauching Soon <span class="dot">.</span>
          <span class="dot">.</span>
          <span class="dot">.</span>
        </h1>
        <p className="maintenance_lines">
          Sorry for the inconvenience but we're in development Mode right now. Please check back later.{' '}
        </p>
      </MaintananceWrapper>
    </Main>
  );
};

export default Maintenance;
