import Axios from 'axios';
import Cookies from 'js-cookie';
import { decrypt } from './encryption-decryption';
import { headers } from './variables';
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

const api_url = [];

api_url['forgotpassword'] = '/user/forgotpassword/';
api_url['verifyotp'] = '/user/verifyotp/';
api_url['passwordupdate'] = '/user/passwordupdate/';

//
api_url['get_allentity'] = '/statusentity/all';
/*UserList */
api_url['user'] = '/user/admin/';
api_url['delete_user'] = '/user/';

/*Dashboard-add_user */
api_url['admin_user_role'] = '/role/admin/';
api_url['admin_user_storetype_role'] = '/role/admin/storetype/';
api_url['store_type_all'] = '/storetype/all'; //payload->all, store, list
api_url['add_user'] = '/user/';
api_url['user_single'] = '/user/';
api_url['update_user'] = '/user/';

/*Update_settings */
api_url['update_settings'] = '/configuration/';
api_url['get_settings'] = '/configuration';

/*Pages */
api_url['create_pages'] = '/page/';
api_url['get_pages'] = '/page/';

/*Subscription */
api_url['subscriptionplans'] = '/subscription/plan/';
api_url['subscriptionplansdelete'] = '/subscription/plan/';

/*Orders */
api_url['ordersby_storeID'] = '/orders/store/';
api_url['orders_all'] = '/orders/all';
api_url['orders_parcelall'] = '/orders/parcelall';
api_url['orders_parcelbyid'] = '/orders/parcel/';
api_url['orderbyID'] = '/orders/';
api_url['intparcelorderbyID'] = '/orders/parcel/';
api_url['get_all_customers'] = '/customer/';
api_url['get_ordersby_customersID'] = '/orders/customer/';
api_url['get_all_payment_method'] = '/paymentmethod/';
api_url['get_all_status'] = '/statusentity/all';
api_url['get_all_coupon'] = '/coupon/';
api_url['get_coupon_byid'] = '/coupon/';
api_url['add_order'] = '/orders/';
api_url['get_status_by_entityid'] = '/status/entity/';

/*Tickets */
api_url['ticketsall'] = '/ticket/all';

/*Transaction */
api_url['get_transaction_byid'] = '/ordertransaction/';
api_url['update_transaction_byid'] = '/ordertransaction/';

/*Refunds */
api_url['create_refund'] = '/refund/';
api_url['get_refund_byorderid'] = '/refund/order/';
api_url['get_refund_byid'] = '/refund/';
api_url['orderpickupbyid'] = '/orders/store/readyforpickup';

/*AddAttribute */
api_url['get_attributes_bystoreID'] = '/attributes/allattributes&itsvalues/store/';
api_url['get_allattributes'] = '/attributes/allattributes&itsvalues';
api_url['delete_attributes'] = '/attributes/delete/';
api_url['checkattributealready'] = '/attributes/checkattributealready';
api_url['create_attributes'] = '/attributes/';
api_url['create_attributevalues'] = '/attributes/attributevalues';

/*AddStore */
api_url['get_all_storetypes'] = '/storetype/all';
api_url['get_all_storeusers'] = '/user/store/';
api_url['get_storeadmin_byuserID'] = '/user/storeadmin/sel/';
api_url['get_user_bystoretypeID'] = '/user/single/sel/';
api_url['get_storepostion_bystoretype'] = '/store/positions/';
api_url['createstore'] = '/store/add';
api_url['getstoreby_userID'] = '/store/all/user/';
api_url['getstore_byID'] = '/store/single/';

/*Store Type */
api_url['get_allmodules'] = '/module/allNamesAndIds';
api_url['storetype_allPositions'] = '/storetype/allPositions';
api_url['create_storetype'] = '/storetype/';
api_url['get_storetype_single'] = '/storetype/single/';
api_url['update_storetype'] = '/storetype/update/';

/*Store Role List */

api_url['delete_store_role_byid'] = '/role/';
api_url['get_roleslist_bystore_type'] = '/role/all/storetype/';
api_url['get_storeby_storetype'] = '/store/type/';

const get_rolesby_store = id => {
  api_url['get_rolesby_store'] = `/store/${id}/role/`;
  return api_url['get_rolesby_store'];
};

api_url['get_ticket_comments'] = '/ticketcomments/ticket/';

/*Edit Category */
api_url['get_productcategory_single'] = '/productcategory/single/';

/*Store Modules */
api_url['get_modulesingle_byID'] = '/module/single/';
api_url['get_allmodules'] = '/module/all';
api_url['module_checkmodulealready'] = '/module/checkmodulealready';
api_url['update_module'] = '/module/';

/*Store Discount */
api_url['get_storesall'] = '/store/all';
api_url['discount_checkalready'] = '/discount/checkalready';
api_url['create_discount'] = '/discount/';

/*Store Discount */
api_url['customer_delete'] = '/customer/delete/';

/*Customer */
api_url['customer_Update'] = '/customer/';
api_url['customer_Create'] = '/customer/';
api_url['create_cart'] = '/cart';
//

/*Order  */
api_url['getproduct_bystoreid'] = '/products/store/';

/*Edit Product  */
api_url['productcategory_bystoreid'] = '/productcategory/store/';
api_url['productcategoryall'] = '/productcategory/all';
api_url['store_allNamesAndIds'] = '/store/allNamesAndIds';
api_url['attributes_allNamesAndIds'] = '/attributes/allNamesAndIds';
api_url['products_type_all'] = '/products/type/all';

/*Delivery Order  */
api_url['createInternationOrder'] = '/orders/parcel/international';
api_url['createDomesticOrder'] = '/orders/parcel/';
api_url['createlagguageOrder'] = '/orders/parcel/luggage';
api_url['createParcelOrder'] = '/orders/parcel/parcel';
api_url['createRushOnDemandOrder'] = '/orders/parcel/rush';
api_url['getorders_bydeliveryUserID'] = '/delivery/ordersbyuserid/';

/*WithAdminLayout */
api_url['create_message'] = '/chatmessage';
api_url['getMessage_bychatID'] = '/chatmessage/';

/*Message */
api_url['get_allmessages_byuserID/'] = '/chat/messages/';
api_url['get_AllUserschat_byUserID'] = '/chat/';

/*Request Function Starts +++++*/
const get_api_request = (url, headers = null) => {
  // console.log(url);
  const promise1 = new Promise((resolve, reject) => {
    Axios.get(domainpath + url, { headers })
      .then(response => {
        resolve(response);
        // console.log(response);
      })
      .catch(error => {
        const Error = {
          status: 'error',
          message: error,
        };
        reject(Error);
      });
  });
  const response_promise = promise1
    .then(value => {
      //console.log(value);
      return value;
    })
    .catch(error => {
      const Error = {
        status: 'error',
        message: error,
      };
      return Error;
    });
  return response_promise;
};
//--------------------------------------------------------
const post_api_request = (url, payload, headers = null) => {
  // Axios.post(domainpath + url, payload, { headers }).then(response => {
  //   return response;
  // });
  const promise1 = new Promise((resolve, reject) => {
    Axios.post(domainpath + url, payload, { headers })
      .then(response => {
        //console.log(response);
        resolve(response);
      })
      .catch(error => {
        const Error = {
          status: 'error',
          message: error,
        };
        reject(Error);
      });
  });
  const response_promise = promise1
    .then(value => {
      return value;
    })
    .catch(error => {
      const Error = {
        status: 'error',
        message: error,
      };
      return Error;
    });
  return response_promise;
};
//---------------------------------------------------------
const put_api_request = (url, payload, headers = null) => {
  // Axios.put(domainpath + url, payload, { headers }).then(response => {
  //   return response;
  // });
  const promise1 = new Promise((resolve, reject) => {
    Axios.put(domainpath + url, payload, { headers })
      .then(response => {
        //console.log(response);
        resolve(response);
      })
      .catch(error => {
        const Error = {
          status: 'error',
          message: error,
        };
        reject(Error);
      });
  });
  const response_promise = promise1
    .then(value => {
      return value;
    })
    .catch(error => {
      const Error = {
        status: 'error',
        message: error,
      };
      return Error;
    });
  return response_promise;
};
//----------------------------------------------------------
const delete_api_request = (url, headers = null) => {
  // Axios.delete(domainpath + url, payload, { headers }).then(response => {
  //   return response;
  // });
  //console.log(url);
  const promise1 = new Promise((resolve, reject) => {
    Axios.delete(domainpath + url, { headers })
      .then(response => {
        //console.log(response);
        resolve(response);
      })
      .catch(error => {
        const Error = {
          status: 'error',
          message: error,
        };
        reject(Error);
      });
  });
  const response_promise = promise1
    .then(value => {
      return value;
    })
    .catch(error => {
      const Error = {
        status: 'error',
        message: error,
      };
      return Error;
    });
  return response_promise;
};
/*++++++++Get Module Permissions From Cookies +++++++++++++++++STARTS*/
const ModulePermissions = ModuleName => {
  var Array_of_Modules = [];
  var UserRole = [];
  var currentStoreModules = [];
  var ModulePermissions = {};
  var getModule = [];

  const enc_user_detail = Cookies.get('UserDetail');
  var userDetail = decrypt(enc_user_detail);
  var currectStoreID = userDetail?.currentstore?.store_id;
  var currectUserRoleID = userDetail?.currentstore?.user_role_id;
  var userStore = userDetail?.store;
  var login_stort_TypeID = userDetail?.store?.[0].store_type_id;
  //get User Role dynamicaly ----
  userStore?.map(item => {
    if (currectUserRoleID == item.user_role_id) {
      UserRole = item?.user_role.toUpperCase();
    }
  });

  //get Modules dynamicaly ----
  userStore?.map(item => {
    if (item.store_id == currectStoreID) {
      currentStoreModules = item.permissions?.[UserRole].MODULES;
    }
  });
  var Modules_key = Object.keys(currentStoreModules); // to check is modules available or not?
  Modules_key?.map((item, value) => {
    if (item == ModuleName) {
      return (getModule = item);
    }
  });
  //------------ Make array for all modules------------start

  var modules_array = Object.entries(currentStoreModules).map(([key, value]) => ({ key, value }));

  Array_of_Modules = modules_array;
  modules_array?.map(item => {
    if (item.key == ModuleName) {
      ModulePermissions = { item: item, role: UserRole };
      // const perminssion_values = item.value.split(','); // get module value
      // ModulePermissions['view'] = perminssion_values[0];
      // ModulePermissions['add'] = perminssion_values[1];
      // ModulePermissions['edit'] = perminssion_values[2];
      // ModulePermissions['delete'] = perminssion_values[3];
    }
  });
  return ModulePermissions;

  //---------------make array for all modules---------------end
};

/*++++++++Get Module Permissions From Cookies +++++++++++++++++ENDS*/
export {
  get_api_request,
  post_api_request,
  put_api_request,
  delete_api_request,
  get_rolesby_store,
  api_url,
  ModulePermissions,
};
