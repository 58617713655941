import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const AddProduct = lazy(() => import('../../container/pages/AddProduct'));
const EditProduct = lazy(() => import('../../container/pages/EditProduct'));
const ViewProduct = lazy(() => import('../../container/pages/ViewProduct'));
const ProductGrid = lazy(() => import('../../container/pages/ProductGrid'));
const AddAttributes = lazy(() => import('../../container/pages/AddAttributes'));


const ProductsRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/add-product`} component={AddProduct} />
      <Route path={`${path}/edit-product/:id`} component={EditProduct} />
      <Route path={`${path}/view-product/:id`} component={ViewProduct} />
      <Route path={`${path}/product-grid`} component={ProductGrid} />
      <Route path={`${path}/add-attributes`} component={AddAttributes} />
    </Switch>
  );
};

export default ProductsRoute;
