import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// const Users = lazy(() => import('../../container/pages/Users'));
const AddUser = lazy(() => import('../../container/pages/AddUsers'));
const EditUser = lazy(() => import('../../container/pages/EditUsers'));
const ViewUser = lazy(() => import('../../container/pages/ViewUsers'));
const UsersList = lazy(() => import('../../container/pages/UsersList'));
const UsersRole = lazy(() => import('../../container/pages/UsersRole'));
const AddUsersRole = lazy(() => import('../../container/pages/AddUsersRole'));
const UsersPermission = lazy(() => import('../../container/pages/UsersPermission'));
const UsersRolePermissions = lazy(() => import('../../container/pages/UsersRolePermissions'));
// const AddNewStoreUser = lazy(() => import('../../container/pages/AddStoreUserList'));
const AddCustomer = lazy(() => import('../../container/pages/AddCustomer'));
// const AddPartner = lazy(() => import('../../container/pages/AddPartner'));
// const DataTable = lazy(() => import('../../container/pages/UserListDataTable'));
// const RolesDataTable = lazy(() => import('../../container/pages/UserRolesDataTable'));
// const ModulesDataTable = lazy(() => import('../../container/pages/ModulesListTable'));
// const Team = lazy(() => import('../../container/pages/Team'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* <Route path={`${path}/add-user`} component={AddUser} />
      <Route path={`${path}/edit-user/:id`} component={EditUser} />
      <Route path={`${path}/view-user/:id`} component={ViewUser} />
      <Route path={`${path}/users`} component={UsersList} />
      <Route path={`${path}/roles`} component={UsersRole} />
      <Route path={`${path}/add-users-role`} component={AddUsersRole} />
      <Route path={`${path}/users-permission`} component={UsersPermission} />
      <Route path={`${path}/users-role-permissions`} component={UsersRolePermissions} />
      <Route path={`${path}/add-customer`} component={AddCustomer} /> */}

      {/* <Route path={`${path}/add-user-new`} component={AddNewStoreUser} /> */}
      {/* <Route path={`${path}/add-partner`} component={AddPartner} />
      <Route path={`${path}/dataTable`} component={DataTable} />
      <Route path={`${path}/rolesDataTable`} component={RolesDataTable} />
      <Route path={`${path}/modulesDataTable`} component={ModulesDataTable} />
      <Route path={`${path}/team`} component={Team} />
      <Route path={`${path}`} component={Users} /> */}
    </Switch>
  );
};

export default PagesRoute;
