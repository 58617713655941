import React, { lazy } from 'react';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { Route, useRouteMatch } from 'react-router-dom';

const Product = lazy(() => import('../../container/ecommerce/product/Products'));
const ProductList = lazy(() => import('../../container/ecommerce/ProductList'));
const ProductAdd = lazy(() => import('../../container/ecommerce/product/AddProduct'));
const ProductInventory = lazy(() => import('../../container/ecommerce/ProductInventory'));
const ProductEdit = lazy(() => import('../../container/ecommerce/product/EditProduct'));
const ProductDetails = lazy(() => import('../../container/ecommerce/product/ProductDetails'));

const Invoice = lazy(() => import('../../container/ecommerce/Invoice'));
const AddInvoice = lazy(() => import('../../container/ecommerce/AddInvoice'));
const ViewInvoice = lazy(() => import('../../container/ecommerce/ViewInvoice'));
const EditInvoice = lazy(() => import('../../container/ecommerce/EditInvoice'));

const Orders = lazy(() => import('../../container/ecommerce/Orders'));
const AddOrder = lazy(() => import('../../container/ecommerce/AddOrder'));
const ViewOrder = lazy(() => import('../../container/ecommerce/ViewOrder'));
const EditOrder = lazy(() => import('../../container/ecommerce/EditOrder'));

const Categories = lazy(() => import('../../container/ecommerce/Categories'));
const AddCategory = lazy(() => import('../../container/ecommerce/AddCategory'));
const EditCategory = lazy(() => import('../../container/ecommerce/EditCategory'));
const ViewCategory = lazy(() => import('../../container/ecommerce/ViewCategory'));

const SubCategories = lazy(() => import('../../container/ecommerce/SubCategories'));
const AddSubCategories = lazy(() => import('../../container/ecommerce/AddSubCategories'));
const EditSubCategories = lazy(() => import('../../container/ecommerce/EditSubCategories'));
const ViewSubCategories = lazy(() => import('../../container/ecommerce/ViewSubCategories'));

const Reports = lazy(() => import('../../container/ecommerce/Reports'));

const AddSubscriptionPlan = lazy(() => import('../../container/ecommerce/AddSubscriptionPlan'));
const EditSubscriptionPlan = lazy(() => import('../../container/ecommerce/EditSubscriptionPlan'));
const SubscriptionPlans = lazy(() => import('../../container/ecommerce/SubscriptionPlans'));

const SubscribedUsers = lazy(() => import('../../container/ecommerce/SubscribedUsers'));
const AddSubscribedUser = lazy(() => import('../../container/ecommerce/AddSubscribedUser'));
const EditSubscribedUser = lazy(() => import('../../container/ecommerce/EditSubscribedUser'));
const ViewSubscribedUser = lazy(() => import('../../container/ecommerce/ViewSubscribedUser'));

const Refunds = lazy(() => import('../../container/ecommerce/Refunds'));
const AddRefund = lazy(() => import('../../container/ecommerce/AddRefund'));
const ViewRefund = lazy(() => import('../../container/ecommerce/ViewRefund'));
const EditRefund = lazy(() => import('../../container/ecommerce/EditRefund'));

const Transactions = lazy(() => import('../../container/ecommerce/Transactions'));
const AddTransaction = lazy(() => import('../../container/ecommerce/AddTransaction'));
const ViewTransaction = lazy(() => import('../../container/ecommerce/ViewTransaction'));
const EditTransaction = lazy(() => import('../../container/ecommerce/EditTransaction'));

const Tickets = lazy(() => import('../../container/ecommerce/Tickets'));
const AddTicket = lazy(() => import('../../container/ecommerce/AddTicket'));
const ViewTicket = lazy(() => import('../../container/ecommerce/ViewTicket'));
const EditTicket = lazy(() => import('../../container/ecommerce/EditTicket'));

const CustomersList = lazy(() => import('../../container/ecommerce/CustomersList'));
const PartnerList = lazy(() => import('../../container/ecommerce/PartnerList'));
const PartnersRoles = lazy(() => import('../../container/ecommerce/PartnersRoles'));

const StoreModules = lazy(() => import('../../container/ecommerce/StoreModules'));
const ModulesList = lazy(() => import('../../container/ecommerce/ModulesList'));

const InvoiceList = lazy(() => import('../../container/ecommerce/InvoiceList'));
const Sellers = lazy(() => import('../../container/ecommerce/Sellers'));
const Cart = lazy(() => import('../../container/ecommerce/Cart'));

const EcommerceRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/products`} component={Product} />
      <Route path={`${path}/product-list`} component={ProductList} />
      <Route exact path={`${path}/add-product`} component={ProductAdd} />
      <Route exact path={`${path}/product-inventory`} component={ProductInventory} />
      <Route exact path={`${path}/edit-product`} component={ProductEdit} />
      <Route exact path={`${path}/productDetails/:id`} component={ProductDetails} />

      <Route exact path={`${path}/invoice`} component={Invoice} />
      <Route exact path={`${path}/add-invoice`} component={AddInvoice} />
      <Route exact path={`${path}/view-invoice`} component={ViewInvoice} />
      <Route exact path={`${path}/edit-invoice`} component={EditInvoice} />

      <Route exact path={`${path}/orders`} component={Orders} />
      <Route exact path={`${path}/add-order`} component={AddOrder} />
      <Route exact path={`${path}/view-order`} component={ViewOrder} />
      <Route exact path={`${path}/edit-order`} component={EditOrder} />

      <Route exact path={`${path}/categories`} component={Categories} />
      <Route path={`${path}/add-category`} component={AddCategory} />
      <Route path={`${path}/edit-category/:id`} component={EditCategory} />
      <Route path={`${path}/view-category/:id`} component={ViewCategory} />

      <Route exact path={`${path}/reports`} component={Reports} />

      <Route exact path={`${path}/subcategories`} component={SubCategories} />
      <Route path={`${path}/add-subcategories`} component={AddSubCategories} />
      <Route path={`${path}/edit-subcategories`} component={EditSubCategories} />
      <Route path={`${path}/view-subcategories`} component={ViewSubCategories} />

      <Route exact path={`${path}/subscribedusers`} component={SubscribedUsers} />
      <Route exact path={`${path}/add-subscribed-user`} component={AddSubscribedUser} />
      <Route exact path={`${path}/edit-subscribed-user/:id`} component={EditSubscribedUser} />
      <Route exact path={`${path}/view-subscribed-user/:id`} component={ViewSubscribedUser} />

      <Route exact path={`${path}/subscription-plans`} component={SubscriptionPlans} />
      <Route exact path={`${path}/add-subscription-plan`} component={AddSubscriptionPlan} />
      <Route exact path={`${path}/edit-subscription-plan`} component={EditSubscriptionPlan} />

      <Route exact path={`${path}/refunds`} component={Refunds} />
      <Route exact path={`${path}/add-refund`} component={AddRefund} />
      <Route exact path={`${path}/view-refund`} component={ViewRefund} />
      <Route exact path={`${path}/edit-refund`} component={EditRefund} />

      <Route exact path={`${path}/transactions`} component={Transactions} />
      <Route exact path={`${path}/add-transaction`} component={AddTransaction} />
      <Route exact path={`${path}/view-transaction`} component={ViewTransaction} />
      <Route exact path={`${path}/edit-transaction`} component={EditTransaction} />

      <Route exact path={`${path}/tickets`} component={Tickets} />
      <Route exact path={`${path}/add-ticket`} component={AddTicket} />
      <Route exact path={`${path}/view-ticket`} component={ViewTicket} />
      <Route exact path={`${path}/edit-ticket`} component={EditTicket} />

      <Route exact path={`${path}/customerslist`} component={CustomersList} />
      <Route exact path={`${path}/partnerlist`} component={PartnerList} />
      <Route exact path={`${path}/partner-roles`} component={PartnersRoles} />

      <Route exact path={`${path}/moduleslist`} component={ModulesList} />
      <Route exact path={`${path}/store-modules`} component={StoreModules} />

      <Route exact path={`${path}/invoicelist`} component={InvoiceList} />

      <Route exact path={`${path}/sellers`} component={Sellers} />
      <Route path={`${path}/cart`} component={Cart} />
    </Switch>
  );
};

export default EcommerceRoute;
