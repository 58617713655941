import React, { lazy } from 'react';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { Route, useRouteMatch } from 'react-router-dom';

const AddBanners = lazy(() => import('../../container/pages/AddBanners'));
const ViewBanners = lazy(() => import('../../container/pages/ViewBanners'));
const EditBanners = lazy(() => import('../../container/pages/EditBanners'));
const BannersList = lazy(() => import('../../container/pages/BannersList'));
const AddHeaders = lazy(() => import('../../container/pages/AddHeaders'));
const AddFooter = lazy(() => import('../../container/pages/AddFooter.js'));

const BannersRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/add-banners`} component={AddBanners} />
      <Route exact path={`${path}/view-banners/:id`} component={ViewBanners} />
      <Route exact path={`${path}/edit-banners/:id`} component={EditBanners} />
      <Route exact path={`${path}/banners-list`} component={BannersList} />

      <Route exact path={`${path}/custom-header`} component={AddHeaders} />
      <Route exact path={`${path}/custom-footer`} component={AddFooter} />
    </Switch>
  );
};

export default BannersRoute;
