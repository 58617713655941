import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
//khush added
//ends
if (window.location.host != "localhost:3001") {
    if (window.location.protocol.toLowerCase() !== "https:") {
      const url = `https://${window.location.host}`;
      window.location.replace(url);
    }
  }
ReactDOM.render(<App />, document.getElementById('root'));
//console.log('I am in Index.js');
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
