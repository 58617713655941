import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { get_api_request, post_api_request, put_api_request, delete_api_request, api_url } from '../helpers/Api';
import { headers } from '../helpers/variables';
const { encrypt, decrypt } = require('../helpers/encryption-decryption');
const { encrypttheid, decodetheid } = require('../helpers/encode-decode');

const ChatBox = () => {
  const [showChatBox, setShowChatBox] = useState(false);
  const [MessageData, setMessageData] = useState('');
  const [UserID, setUserID] = useState();
  const [ReciverData, setReciverData] = useState([]);
  const [SenderData, setSenderData] = useState([]);
  const [ChatID, setChatID] = useState({
    chat_id: '',
    id: '',
  });
  useEffect(() => {
    const enc_user_detail = Cookies.get('UserDetail');
    const response = decrypt(enc_user_detail);
    if (response) {
      const userID = decodetheid(response.users_id);
      setUserID(userID);
    }
  }, []);

  const UserChat = async e => {
    console.log('jkjlklkl555');
    setShowChatBox(!showChatBox);
    // const data = {
    //   sender_id: UserID,
    // };
    // const url = api_url.create_message;
    // const response = await post_api_request(url, data, headers);
    // console.log(response);
    // if (response?.status == 201) {
    //   const chat_id = response?.data?.response?.chat_id;
    //   const id = response?.data?.response?.id;
    //   setChatID({
    //     chat_id: chat_id,
    //     id: id,
    //   });
    // }
  };
  const PostComments = e => {
    e.preventDefault();
    console.log(ChatID);
    console.log(ChatID.chat_id);
    var payload = {};
    payload['sender_id'] = UserID;
    payload['message'] = MessageData;
    if (ChatID.chat_id) {
      payload['chat_id'] = ChatID.chat_id;
    }
    CreateMessage(payload);
  };

  async function CreateMessage(data) {
    var reciverInfo = [];
    console.log(data);
    const url = api_url.create_message;
    const response = await post_api_request(url, data, headers);
    console.log(response);
    if (response?.status == 201) {
      const chat_id = response?.data?.response?.chat_id;
      const id = response?.data?.response?.id;
      setChatID({
        chat_id: chat_id,
        id: id,
      });
      const encrypted_chatID = encrypttheid(response?.data?.response?.chat_id);
      const url = api_url.getMessage_bychatID + encrypted_chatID;
      console.log(url);
      const response1 = await get_api_request(url, headers);
      console.log(response1);
      if (response1?.status == 200) {
        const chatData = response1?.data?.responsedata;
        setSenderData(chatData);
      }
      setMessageData('');
    }
  }

  return (
    <>
      <div className="Main-commentBox">
        {showChatBox == true ? (
          <div className="chatbox">
            <div className="commentBox">
              <div className="innerChat">
                {SenderData?.map(item => (
                  <p style={{ backgroundColor: 'beige' }}>{item?.message}</p>
                ))}
                {/* <p>oh! realy</p> */}
              </div>
              <textarea
                // className="txt_area"
                name="comments"
                placeholder="Write a Comment Here"
                style={{
                  padding: '0px 10px 0px 0px',
                  resize: 'none',
                }}
                rows="3"
                value={MessageData}
                onChange={events => setMessageData(events.target.value)}
              ></textarea>
              <i class="fa fa-paper-plane" aria-hidden="true" onClick={PostComments}></i>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <NavLink to="#">
        <i class="fa fa-commenting-o" aria-hidden="true" style={{ fontSize: '30px' }} onClick={e => UserChat(e)}></i>
      </NavLink>
    </>
  );
};

export default ChatBox;
