import React from 'react';
import Cookies from 'js-cookie';

// const HEADERS = {
//   headers: {
//     'Content-Type': 'application/json; charset=UTF-8',
//     token: 'Bearer hjskdhskjdhsjkdhskjdhskjdhskdhskjdhsdjksjhdsjkdsdks',
//   },
// };
const { encrypt, decrypt } = require('../helpers/encryption-decryption');
import { DeviceUUID } from 'device-uuid';
const access_token = Cookies.get('access_token');
//console.log(access_token);
var du = new DeviceUUID().parse();
var dua = [
  du.language,
  du.platform,
  du.os,
  du.cpuCores,
  du.isAuthoritative,
  du.silkAccelerated,
  du.isKindleFire,
  du.isDesktop,
  du.isMobile,
  du.isTablet,
  du.isWindows,
  du.isLinux,
  du.isLinux64,
  du.isMac,
  du.isiPad,
  du.isiPhone,
  du.isiPod,
  du.isSmartTV,
  du.pixelDepth,
  du.isTouchScreen,
];
var uuid = du.hashMD5(dua.join(':'));
// if (access_token_cookie && access_token_cookie.length > 0) {
//   access_token = decrypt(access_token_cookie);
//   console.log(access_token);
// }
// if (sessionStorage.length > 0) {
//   // var access_token = sessionStorage.getItem('access_token');
//   // console.log(access_token);

//   var enc_access_token = sessionStorage.getItem('access_token');
//   console.log(enc_access_token);

//   var access_token = decrypt(enc_access_token);
//   console.log(access_token);

//   var enc_user_detail = Cookies.get('UserDetail');
//   console.log(enc_user_detail);

//   var UserDetail = decrypt(enc_user_detail);
//   console.log(UserDetail);
// }
const session_id = Cookies.get('sessionID');
const headers = {
  'Content-Type': 'application/json; charset=UTF-8',
  token: 'Bearer ' + access_token,
  //token: [access_token],
  device_id: uuid,
  device_type: du.platform,
  api_version: '1',
  session_id: session_id,
};

export {
  headers,
  // UserDetail
};

//device_id
//api_version //env file
//browser
//device_type
