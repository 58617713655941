import React, { lazy } from 'react';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { Route, useRouteMatch } from 'react-router-dom';
//
const StoreType = lazy(() => import('../../container/pages/StoreType.js'));
const AddStoreType = lazy(() => import('../../container/pages/AddStoretype'));
const ViewStoreType = lazy(() => import('../../container/pages/ViewStoretype'));
const EditStoreType = lazy(() => import('../../container/pages/EditStoretype'));
//
const StoresList = lazy(() => import('../../container/pages/StoresList'));
const AddStoreList = lazy(() => import('../../container/pages/AddStoreList'));
const AddStoreDemo = lazy(() => import('../../container/pages/AddStoreDemo')); //
const EditStoreDemo = lazy(() => import('../../container/pages/EditStoreDemo')); //
const EditStoreList = lazy(() => import('../../container/pages/EditStoreList'));
const ViewStoreList = lazy(() => import('../../container/pages/ViewStoreList'));

//Add new store become our partner
const AddNewStore = lazy(() => import('../../container/pages/AddNewStore'));
const ViewNewStore = lazy(() => import('../../container/pages/ViewNewStore'));
const EditNewStore = lazy(() => import('../../container/pages/EditNewStore'));
//
// const ViewStoreList = lazy(() => import('../../container/pages/ViewStoreList'));
// const EditStoreList = lazy(() => import('../../container/pages/EditStoreList'));
//
const StoreUsersList = lazy(() => import('../../container/pages/StoreUsersList'));
const AddStoreUserList = lazy(() => import('../../container/pages/AddStoreUserList'));
const EditStoreUserList = lazy(() => import('../../container/pages/EditStoreUserList'));
const ViewStoreUserList = lazy(() => import('../../container/pages/ViewStoreUserList'));
//
const StoreDiscountList = lazy(() => import('../../container/pages/StoreDiscountList'));
const AddDiscountList = lazy(() => import('../../container/pages/AddDiscountList'));
const ViewDiscountList = lazy(() => import('../../container/pages/ViewDiscountList'));
const EditDiscountList = lazy(() => import('../../container/pages/EditDiscountList'));
//
const AddStoreModule = lazy(() => import('../../container/pages/AddStoreModule'));
const ViewStoreModule = lazy(() => import('../../container/pages/ViewStoreModule'));
const EditStoreModule = lazy(() => import('../../container/pages/EditStoreModule'));
const StoreModules = lazy(() => import('../../container/pages/StoreModules'));
//---------------------------------------------------------------------------------------------
//for super admin starts
const StoreRoleList = lazy(() => import('../../container/pages/StoreRoleList'));
const StoreRoleListByStoreID = lazy(() => import('../../container/pages/StoreRoleListByStoreID'));
const AddStoreRole = lazy(() => import('../../container/pages/AddStoreRole'));
const EditStoreRole = lazy(() => import('../../container/pages/EditStoreRole'));
const ViewStoreRole = lazy(() => import('../../container/pages/ViewStoreRole'));
//for super admin ends
//for admin starts
const S_StoreRoleList = lazy(() => import('../../container/pages/S_StoreRoleList'));
const S_AddStoreRole = lazy(() => import('../../container/pages/S_AddStoreRole'));
const S_EditStoreRole = lazy(() => import('../../container/pages/S_EditStoreRole'));
const S_ViewStoreRole = lazy(() => import('../../container/pages/S_ViewStoreRole'));
//for admin ends
//---------------------------------------------------------------------------------------------
const StoresRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* 1 */}
      <Route exact path={`${path}/stores`} component={StoresList} />
      <Route exact path={`${path}/add-list`} component={AddStoreList} />
      <Route exact path={`${path}/add-demo`} component={AddStoreDemo} />
      <Route exact path={`${path}/edit-demo/:id`} component={EditStoreDemo} />
      <Route exact path={`${path}/view/:id`} component={ViewStoreList} />
      <Route exact path={`${path}/edit/:id`} component={EditStoreList} />
      {/* 1 */}
      {/* 2 */}
      <Route exact path={`${path}/types`} component={StoreType} />
      <Route exact path={`${path}/add-store`} component={AddStoreType} />
      <Route exact path={`${path}/view-store/:id`} component={ViewStoreType} />
      <Route exact path={`${path}/edit-store/:id`} component={EditStoreType} />
      {/* 2 */}
      {/* 3 */}
      <Route exact path={`${path}/users`} component={StoreUsersList} />
      <Route exact path={`${path}/add-storeuser-list`} component={AddStoreUserList} />
      <Route exact path={`${path}/edit-storeuser-list/:id`} component={EditStoreUserList} />
      <Route exact path={`${path}/view-storeuser-list/:id`} component={ViewStoreUserList} />
      {/* 3 */}
      {/* 4 */}
      {/* super admin ---starts */}
      <Route exact path={`${path}/store-role-list`} component={StoreRoleList} />
      <Route exact path={`${path}/store-role-list-id/:id`} component={StoreRoleListByStoreID} />
      <Route exact path={`${path}/add-store-role`} component={AddStoreRole} />
      <Route exact path={`${path}/edit-store-role/:id`} component={EditStoreRole} />
      <Route exact path={`${path}/view-store-role/:id`} component={ViewStoreRole} />
      {/* super admin ---ends */}
      {/* store admin ---starts */}
      <Route exact path={`${path}/store-role-lists`} component={S_StoreRoleList} />
      <Route exact path={`${path}/add-store-roles`} component={S_AddStoreRole} />
      <Route exact path={`${path}/edit-store-roles/:id`} component={S_EditStoreRole} />
      <Route exact path={`${path}/view-store-roles/:id`} component={S_ViewStoreRole} />
      {/* store admin ---ends */}
      {/* 4 */}

      {/* 5 */}
      <Route exact path={`${path}/modules`} component={StoreModules} />
      <Route exact path={`${path}/add-modules`} component={AddStoreModule} />
      <Route exact path={`${path}/view-modules/:id`} component={ViewStoreModule} />
      <Route exact path={`${path}/edit-modules/:id`} component={EditStoreModule} />
      {/* 5 */}

      {/* 6 */}
      <Route exact path={`${path}/storediscountlist`} component={StoreDiscountList} />
      <Route exact path={`${path}/add-Discount-store`} component={AddDiscountList} />
      <Route exact path={`${path}/edit-Disc-list/:id`} component={EditDiscountList} />
      <Route exact path={`${path}/view-Disc-list/:id`} component={ViewDiscountList} />
      {/* 6 */}

      <Route exact path={`${path}/add-new-store`} component={AddNewStore} />
      <Route exact path={`${path}/view-new-store`} component={ViewNewStore} />
      <Route exact path={`${path}/edit-new-store/:id`} component={EditNewStore} />
    </Switch>
  );
};

export default StoresRoute;
