import React, { useState, useEffect } from 'react';
import { Avatar, notification, Button } from 'antd';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import logo from '../../../static/img/flag/store1.png';
import Message from './message';
//import Notification from './notification';
// import Settings from './settings';
import Support from './support';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import Axios from 'axios';
import { headers } from '../../../helpers/variables';
import addNotification from 'react-push-notification';
import logo1 from '../../../static/img/ezmoov_logo.png';
//import Cookies from 'universal-cookie';
import Cookies from 'js-cookie';
//import './firebase-messaging-sw';
//const cookies = new Cookies();

//import { initializeApp } from 'firebase/app';
//import { getMessaging, getToken } from 'firebase/messaging';
import { api_url, get_api_request } from '../../../helpers/Api';
import FirebaseNotification from './FirebaseNotification';
import moment from 'moment';
//importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-app-compat.js');
//importScripts('https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging-compat.js');

const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;
const { encrypttheid, decodetheid } = require('../../../helpers/encode-decode');
const { encrypt, decrypt } = require('../../../helpers/encryption-decryption');
var allStores = [];

var userRole = {};
const AuthInfo = () => {
  const dateFormat = 'DD-MM-YYYY';
  const CurrentDate = moment(new Date()).format(dateFormat);
  const Today = new Date();
  const history = useHistory();
  const [changeStoreDependencyVariable, setchangeStoreDependencyVariable] = useState(true);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    flag: 'english',
  });

  const [storeUser, setStoreUser] = useState({
    user: [],
  });
  const [userRoleName, setUserRoleName] = useState();
  const [storeInfo, setStoreInfo] = useState();
  const { flag } = state;
  const { store } = state;

  //const [currentdS_ID, setCurrentdS_ID] = useState();
  const [selectedStore, setSelectedStore] = useState();
  const [selectedStoreByAdmin, setSelectedStoreByAdmin] = useState();
  const [storeLength, setStoreLength] = useState();
  const [pendingOrders, setPendingOrder] = useState([]);
  const [AllOrders, setAllOrders] = useState([]);
  // const [udatedID, setUpdatedID] = useState();
  var currentStoreName = [];

  /*+++++++ React Push Notification++++++++++++Starts*/
  const [count, setCount] = useState(0);
  var SelectedStoreID = sessionStorage.getItem('storeID'); //Get Selected Store ID
  if (
    userRole != 'super_admin' &&
    userRole?.length != undefined &&
    document.getElementById('ordercount-restaurant') != null
  ) {
    setTimeout(() => {
      console.log(count);
      setCount(count + 1);

      // if (document.getElementById('ordercount-restaurant') != null) {
      //   document.getElementById('ordercount-restaurant').textContent = count;
      // }
    }, 30000);
  }

  /*+++++++ React Push Notification++++++++++++Ends*/

  /*++++++++++++++++++++++ New +++++++++++++++++++++*/

  // const firebaseConfig = {
  //   apiKey: 'AIzaSyDlYg2_3Ze7nL8MV2bU1aLoVoLf0lC5e5Q',
  //   authDomain: 'ezmoov-1bb77.firebaseapp.com',
  //   projectId: 'ezmoov-1bb77',
  //   storageBucket: 'ezmoov-1bb77.appspot.com',
  //   messagingSenderId: '646858750819',
  //   appId: '1:646858750819:web:3bc755daf33cc553ae3156',
  //   measurementId: 'G-7WHV1E5T9V',
  // };

  // const app = initializeApp(firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  // const messaging = getMessaging(app);
  // console.log(messaging);

  // getToken(messaging, {
  //   vapidKey: 'BPvCgTtgPMuBUc7R3tcY2Kml_Q7pIa9jYGKx2vjiYqI_a-AKp0BGqCEjt_erVoQbzuyS8ecehQyvcYUduVGSaak',
  // }).then(currentToken => {
  //   if (currentToken) {
  //     console.log('currentToken', currentToken);
  //   } else {
  //     console.log('currentToken', 'currentToken not received');
  //   }
  // });

  // function requestPermission() {
  //   console.log('Requesting permission...');
  //   Notification.requestPermission().then(permission => {
  //     if (permission === 'granted') {
  //       console.log('Notification permission granted.');
  //       // Initialize Firebase

  //     } else {
  //       console.log('Do not have permission!');
  //     }
  //   });
  // }
  // requestPermission();

  useEffect(() => {
    const BackTime = moment(Today).format('HH') - 2;
    if (SelectedStoreID) {
      GetAllOrdersByStore(SelectedStoreID);
    } else if (userRole == 'delivery_admin') {
      GetDeliveryOrder();
    }

    async function GetAllOrdersByStore(storeID) {
      const url = api_url.ordersby_storeID + storeID;
      const response = await get_api_request(url, headers);
      const AllOrdersData = response?.data?.responsedata?.orders;
      setAllOrders(AllOrdersData);

      if (AllOrders?.length != AllOrdersData?.length) {
        AllOrdersData?.map(item => {
          if (moment(item?.created_at).format(dateFormat) == CurrentDate && item?.status_title == 'PENDING') {
            pendingOrders.push(item);
            addNotification({
              onClick: Event,
              title: 'Order Created',
              subtitle: 'This is a subtitle',
              message: 'This is a new order',
              native: true,
              icon: logo1,
            });
            if (document.getElementById('ordercount-restaurant') != null) {
              document.getElementById('ordercount-restaurant').textContent = pendingOrders.length;
            }
          }
        });
      }
    }
    async function GetDeliveryOrder() {
      const url = api_url.orders_all;
      const response = await get_api_request(url, headers);

      if (response.status === 201) {
        const allordersarray = response?.data?.responsedata?.orders;
        setAllOrders(allordersarray);
        if (AllOrders?.length != allordersarray?.length) {
          allordersarray?.map(item => {
            if (
              item.order_status == '43' &&
              moment(item?.created_at).format(dateFormat) == CurrentDate &&
              BackTime <= moment(item?.created_at).format('HH')
            ) {
              pendingOrders.push(item);
              addNotification({
                onClick: Event,
                title: 'Order Created',
                subtitle: 'This is a subtitle',
                message: 'This is a new order',
                native: true,
                icon: logo1,
              });
              if (document.getElementById('ordercount-restaurant') != null) {
                document.getElementById('ordercount-restaurant').textContent = pendingOrders.length;
              }
            }
          });
        }
      }
    }
  }, [count]);
  useEffect(() => {
    //  setTimeout(() => {
    // Axios.get(domainpath + `/user/login/data`, { headers })
    //   .then(response => {

    async function CheckSystemSetting() {
      const url = api_url.get_settings;
      const response = await get_api_request(url, headers);
      const config_data = response?.data?.responsedata?.configurations[0]?.system_settings_maintenance;
      if (config_data == 'on') {
        history.push('/maintenance');
      }
    }

    const enc_user_detail = Cookies.get('UserDetail');
    const response = decrypt(enc_user_detail);
    // console.log(response);
    if (response?.login == true) {
      if (response?.store?.[0]?.user_role != 'super_admin') {
        CheckSystemSetting();
      }
      userRole = response?.store?.[0]?.user_role;
      const Store_detail = response?.store;
      const currentStoreID = response?.currentstore?.store_id;
      const userInfo = response?.store;
      const length = response?.store?.length;
      const username = response?.store?.[0]?.user_role;

      setUserRoleName(username);

      //console.log('++++++++++++++++++++++++++++++');
      //

      if (currentStoreID != null) {
        Store_detail?.map(item => {
          // console.log(item);
          if (currentStoreID == item.store_id) {
            return (currentStoreName = item.store);
          }
        });
        var S_ID = encrypttheid(currentStoreID);
        sessionStorage.setItem('storeID', S_ID); //create session to hold the Store_ID
        sessionStorage.setItem('storeName', currentStoreName); //create session to hold the Store_Name
        // const enc_name = await encrypt(currentStoreName);
        // Cookies.set('Store_Name', enc_name);
        // sessionStorage.setItem("UserDetail", JSON.stringify(userDetail));

        //
        setStoreLength(length);
        // setCurrentdS_ID(currentStoreID);
        //
        //var SelectedStoreName = sessionStorage.getItem('storeName'); //get Store from Session
        // console.log(currentStoreName);
        setSelectedStore(currentStoreName);
        // setUpdatedID(SelectedStoreName);
        //
        setStoreInfo(userInfo); //to get storeID
      }

      setStoreUser({ user: Store_detail?.[0] });
    } else {
      console.log('nooooooooooooooooooooooooooooooooooooooooooooooo');
    }

    // })
    // .catch(error => console.log(error));
    //}, 1000);
    const postdata = { request: 'list' };
    Axios.post(domainpath + `/store/allNamesAndIds`, postdata, { headers }).then(response => {
      // console.log(response);
      setSelectedStoreByAdmin('SUPER_ADMIN');
      allStores.push({ id: 0, name: 'SUPER_ADMIN' });
      const storedatainitial = response?.data?.responsedata?.stores;
      storedatainitial.map(item => {
        allStores.push(item);
      });
      //allStores = response?.data?.responsedata?.stores;
    });
  }, [changeStoreDependencyVariable]);

  const SignOut = e => {
    //console.log(headers);
    e.preventDefault();
    dispatch(logOut());
    history.push('/login');
  };
  const onSuperAdminStoreHandle = (value, name) => {
    //console.log(value);
    //console.log(name);
    setSelectedStoreByAdmin(name);
  };
  const onStoreHandle = async (value, name) => {
    var changeStoreBody = {};
    const enc_user_detail1 = Cookies.get('UserDetail');
    var response1 = decrypt(enc_user_detail1);
    const enc_id = await encrypt(value);
    const enc_name = await encrypt(name);
    const Store_detail = response1?.store;
    var new_store = {};
    Store_detail?.map(item => {
      if (item.store_id == value) {
        new_store['store_id'] = item.store_id;
        new_store['store_type_id'] = item.store_type_id;
        new_store['user_role_id'] = item.user_role_id;
      }
    });
    response1['currentstore'] = new_store;
    const updateddetail = await encrypt(response1);

    const id = response1?.users_id;
    changeStoreBody['current_store'] = value;
    //updated current store
    Axios.put(domainpath + `/user/${id}`, changeStoreBody, { headers })
      .then(response => {
        if (response.status == 200) {
          setSelectedStore(name);
          Cookies.set('UserDetail', updateddetail);
          window.location.reload(false);
        } else
          notification.error({
            message: 'Server Error',
          });
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: error.response.data.message,
        });
      });
  };

  // for store first==========
  const userStore =
    userRole == 'super_admin' ? (
      <NavAuth>
        {allStores?.map(item => (
          // console.log(item.name),
          <Link key={item.id} onClick={() => onSuperAdminStoreHandle(item.id, item.name)} to="#">
            <span>{item.name}</span>
          </Link>
        ))}
      </NavAuth>
    ) : (
      <NavAuth>
        {storeInfo?.map(item => (
          <Link onClick={() => onStoreHandle(item.store_id, item.store)} to="#">
            {/* <img src={require('../../../static/img/flag/store2.jpg')} alt="" /> */}
            {selectedStore == item.store ? <span className="sID">{item.store}</span> : <span>{item.store}</span>}
          </Link>
        ))}
      </NavAuth>
    );
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        {/* {storeUser?.user?.map((item, i) => { */}
        {/* console.log(item); */}
        <figure
          // key={i}
          className="user-dropdwon__info"
        >
          {storeUser?.user?.image != null ? (
            // <img src={require(storeUser?.user?.image)} alt="" />
            ''
          ) : (
            <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          )}
          <figcaption>
            <Heading as="h5">
              {storeUser?.user?.first_Name} {storeUser?.user?.last_Name}
            </Heading>
            <p>{userRoleName}</p>
          </figcaption>
        </figure>
        {/* })} */}

        {/* <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">Abdullah Bin Talha</Heading>
            <p>UI Expert</p>
          </figcaption>
        </figure> */}
        <ul className="user-dropdwon__links">
          {/* for store==========++++++++++===== */}
          {/* <li className="hoverMYdiv">
            <Link to="#">
              <FeatherIcon icon="home" /> Store
            </Link>

            <div id="myDIV" style={{ display: "none" }}>
              {storeInfo?.map((item) => (
                <Link onClick={() => onStoreHandle(item.store_id, item.store)} to="#">
                  {currentdS_ID == item.store_id ?
                    <span className='sID'>{item.store}</span>
                    :
                    <span>{item.store}</span>
                  }
                </Link>
              ))}
            </div>

          </li> */}
          {/* for store end======++++++++++++====== */}
          <li>
            <Link to="/dashboard/settings/profile">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li>
            <Link to="/dashboard/components/systemsettings">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          {/* <li>
            <Link to="/dashboard/components/paymentmethods">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="/dashboard/eco">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="/dashboard/components/supports">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction sss" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
  };

  //====language
  // const country = (
  //   <NavAuth>
  //     <Link onClick={() => onFlagChangeHandle('english')} to="#">
  //       <img src={require('../../../static/img/flag/english.png')} alt="" />
  //       <span>English</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('germany')} to="#">
  //       <img src={require('../../../static/img/flag/germany.png')} alt="" />
  //       <span>Germany</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('spain')} to="#">
  //       <img src={require('../../../static/img/flag/spain.png')} alt="" />
  //       <span>Spain</span>
  //     </Link>
  //     <Link onClick={() => onFlagChangeHandle('turky')} to="#">
  //       <img src={require('../../../static/img/flag/turky.png')} alt="" />
  //       <span>Turkey</span>
  //     </Link>
  //   </NavAuth>
  // );

  return (
    <InfoWraper>
      <FirebaseNotification />
      {/* //for store first */}
      {/* <script src="https://www.gstatic.com/firebasejs/9.0.0/firebase-app-compat.js"></script>
      <script src="https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging-compat.js"></script> */}
      <div className="nav-author store_change headerstorechose">
        <Dropdown
          placement="bottomRight"
          content={userStore}
          // trigger="click"
          action="click"
        >
          <Link to="#" className="head-example" style={{ color: 'white' }}>
            <span>{userRole == 'super_admin' ? selectedStoreByAdmin : selectedStore}</span>
            {userRole != 'delivery_admin' ? (
              <>
                &nbsp;<i className="fa fa-chevron-down" aria-hidden="true"></i>
              </>
            ) : (
              ''
            )}
          </Link>
        </Dropdown>
      </div>

      <Message />
      {/* <Notification /> */}

      {/* <Settings /> */}
      <Support />

      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
