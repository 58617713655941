import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import Axios from 'axios';
import { headers } from '../helpers/variables';
import Cookies from 'js-cookie';
// import MenuItemsStore from './MenueItemsStore';
// import MenuItemsDelPartner from './MenueItemsDelPartner';
const { encrypt, decrypt } = require('../helpers/encryption-decryption');
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

const { SubMenu } = Menu;

//for Modules-------------start
var UserRole = [];
var currentStoreModules = [];
//for Modules-------------end

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const [state, setState] = useState({});

  const pathName = window.location.pathname;
  // console.log(pathName);
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  //========================================= Store Modules ==========================start
  //var userDetail = JSON.parse(sessionStorage.getItem('UserDetail')); //get UserDetail array from session
  var enc_userDetail = Cookies.get('UserDetail');
  var userDetail = decrypt(enc_userDetail);
  // console.log(userDetail);
  var currectStoreID = userDetail?.currentstore?.store_id; //69
  var currectUserRoleID = userDetail?.currentstore?.user_role_id; //15
  var userStore = userDetail?.store;

  //get User Role dynamicaly ----
  // console.log(userDetail);
  userStore?.map(item => {
    if (currectUserRoleID == item.user_role_id) {
      //15 ==
      UserRole = item?.user_role?.toUpperCase();
    }
  });
  //console.log(UserRole);
  //get Modules dynamicaly ----
  var ifCurrentAvailable = 0;
  // console.log(userStore);
  userStore?.map(item => {
    //console.log(item);
    // console.log(item.store_id);
    //console.log(currectStoreID);
    if (item.store_id == currectStoreID) {
      // console.log(item);
      currentStoreModules = item?.permissions?.[UserRole].MODULES;
      //ifCurrentAvailable = 1;
    }

    //  else {
    //   console.log('False');
    //   const incUserRole = item?.user_role?.toUpperCase();
    //   currentStoreModules = item?.permissions?.[incUserRole].MODULES;
    // }
  });

  // console.log(UserRole);
  // console.log(currentStoreModules);

  // if(currentStoreModules["BANNERS"] !== undefined){
  //   var  perminssions = currentStoreModules["BANNERS"] ; //retuen value
  //   }

  //========================================= Store Modules ==========================end

  useEffect(() => {
    // const postData = {
    //   phoneoremail: "dashboard@gmail.com",
    //   password: "password1@"
    // };
    // Axios.post(domainpath + `/user/login`, postData, { headers }).then(response => {
    //   console.log(response.data.user[0]);
    //   const userInfo = response.data.user[0];
    //   setState({ userInfo });
    // }).catch(error => console.log(error));
    setTimeout(() => {
      // Axios.get(domainpath + `/user/login/data`, { headers })
      //   .then(response => {
      //     console.log(response);
      //     if (response.data.login === true) {
      const enc_user_detail = Cookies.get('UserDetail');
      const response = decrypt(enc_user_detail);
      //console.log(response);
      if (response?.login == true) {
        const userInfo = response?.store;
        //console.log(userInfo);
        setState({ userInfo });
      }
      //   }
      // })
      // .catch(error => console.log(error));
    }, 1000);
  }, []);

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {/* {console.log(state.userInfo?.[0].store_type)} */}

      <>
        {/* {console.log(UserRole)}
        {console.log(currentStoreModules)} */}
        {currentStoreModules['DASHBOARD'] !== undefined ? (
          <>
            {UserRole == 'SUPER_ADMIN'
              ? [
                  <SubMenu key="dashboardl" icon={!topMenu && <FeatherIcon icon="home" />} title="Dashboard">
                    <Menu.Item key="eco">
                      <NavLink onClick={toggleCollapsed} to={`${path}/eco`}>
                        Ecommerce
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="userlist">
                      <NavLink onClick={toggleCollapsed} to={`${path}/users/users`}>
                        Users
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="usersrole">
                      <NavLink onClick={toggleCollapsed} to={`${path}/users/roles`}>
                        Roles
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>,
                  <SubMenu key="global" icon={!topMenu && <FeatherIcon icon="layers" />} title="Global">
                    <Menu.Item key="global">
                      <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/globallist`}>
                        Global Variable
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="status">
                      <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/satatuslist`}>
                        Manage Status
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="entity">
                      <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/entitylists`}>
                        Entites
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>,
                  <SubMenu key="expense" icon={!topMenu && <FeatherIcon icon="gift" />} title="Expense">
                    <Menu.Item key="expense">
                      <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/expenselist`}>
                        Expense
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>,
                  // <SubMenu key="entitys" icon={!topMenu && <FeatherIcon icon="layers" />} title="Entity">
                  //   <Menu.Item key="entitys">
                  //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/entitylists`}>
                  //       Entity
                  //     </NavLink>
                  //   </Menu.Item>
                  // </SubMenu>,

                  <SubMenu
                    key="faq"
                    icon={
                      !topMenu && (
                        <NavLink className="" to={`${path}/ecommerce/faqlist`}>
                          <i class="fa fa-question-circle" aria-hidden="true"></i>
                        </NavLink>
                      )
                    }
                    title="FAQ"
                  >
                    <Menu.Item key="faq">
                      <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/faqlist`}>
                        FAQ
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>,
                ]
              : ''}
          </>
        ) : (
          //<SubMenu key="dashboard" icon={!topMenu && <FeatherIcon icon="home" />} title="Dashboard">
          <Menu.Item key="dashboard" icon={!topMenu && <FeatherIcon icon="home" />}>
            <NavLink to={`${path}`}>Dashboard</NavLink>
          </Menu.Item>
          //</SubMenu>
        )}
        {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/ecommerce/messages`}>
                <FeatherIcon icon="mail" />
              </NavLink>
            )
          }
          key="messages"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/messages`}>
            Messages
          </NavLink>
        </Menu.Item> */}
        {/* {currentStoreModules['STATUS'] !== undefined ? (
          <SubMenu key="status" icon={!topMenu && <FeatherIcon icon="file-text" />} title="Status">
            <Menu.Item key="status">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/satatuslist`}>
                Manage Status
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )} */}
        {/*       
        {currentStoreModules['DASH_USERS'] !== undefined ? (
          <SubMenu key="user" icon={!topMenu && <FeatherIcon icon="users" />} title="Dash-Users"> */}
        {/* <Menu.Item key="addUser">
              <NavLink onClick={toggleCollapsed} to={`${path}/users/add-user/info`}>
                Add User
              </NavLink>
            </Menu.Item> */}
        {/* <Menu.Item key="addUser2">
                <NavLink onClick={toggleCollapsed} to={`${path}/stores/add-storeuser-list`}>
                  Add User Demo
                </NavLink>
              </Menu.Item> */}
        {/* // : ''} */}
        {/* <Menu.Item key="userlist">
              <NavLink onClick={toggleCollapsed} to={`${path}/users/users`}>
                Users
              </NavLink>
            </Menu.Item>

            <Menu.Item key="usersrole">
              <NavLink onClick={toggleCollapsed} to={`${path}/users/roles`}>
                Roles
              </NavLink>
            </Menu.Item> */}
        {/* <Menu.Item key="usersrolepermission">
                <NavLink onClick={toggleCollapsed} to={`${path}/users/users-role-permissions`}>
                  User Role Permissions
                </NavLink>
              </Menu.Item> */}
        {/* <Menu.Item key="userspermission">
          <NavLink onClick={toggleCollapsed} to={`${path}/users/users-permission`}>
            User Permissions
          </NavLink>
        </Menu.Item> */}
        {/* </SubMenu>
        ) : (
          ''
        )} */}

        {currentStoreModules['STORES'] !== undefined ? (
          <SubMenu key="stores" icon={!topMenu && <FeatherIcon icon="shopping-bag" />} title="Stores">
            <Menu.Item key="storeslist">
              <NavLink onClick={toggleCollapsed} to={`${path}/stores/stores`}>
                Stores
              </NavLink>
            </Menu.Item>
            {UserRole == 'SUPER_ADMIN' ? (
              <Menu.Item key="storetype">
                <NavLink onClick={toggleCollapsed} to={`${path}/stores/types`}>
                  Types
                </NavLink>
              </Menu.Item>
            ) : (
              ''
            )}
            <Menu.Item key="storeuserslist">
              <NavLink onClick={toggleCollapsed} to={`${path}/stores/users`}>
                Users
              </NavLink>
            </Menu.Item>
            {UserRole == 'SUPER_ADMIN' ? (
              <Menu.Item key="storeusersrole">
                <NavLink onClick={toggleCollapsed} to={`${path}/stores/store-role-list`}>
                  Roles
                </NavLink>
              </Menu.Item>
            ) : (
              <Menu.Item key="storeusersrole">
                <NavLink onClick={toggleCollapsed} to={`${path}/stores/store-role-lists`}>
                  Roles
                </NavLink>
              </Menu.Item>
            )}

            {UserRole == 'SUPER_ADMIN' ? (
              <Menu.Item key="store-modules">
                <NavLink onClick={toggleCollapsed} to={`${path}/stores/modules`}>
                  Modules
                </NavLink>
              </Menu.Item>
            ) : (
              ''
            )}
            <Menu.Item key="storediscountlist">
              <NavLink onClick={toggleCollapsed} to={`${path}/stores/storediscountlist`}>
                Discount
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}

        {currentStoreModules['CATEGORIES'] !== undefined ? (
          <SubMenu key="Categories" icon={!topMenu && <FeatherIcon icon="layers" />} title="Categories">
            {/* <Menu.Item key="add-category">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/add-category`}>
                Add Category
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key="categories-list">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/categories`}>
                Categories List
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}

        {currentStoreModules['PRODUCTS'] !== undefined && UserRole != 'VIEWER' ? (
          <SubMenu key="Products" icon={!topMenu && <FeatherIcon icon="package" />} title="Products">
            <Menu.Item key="addproduct">
              <NavLink onClick={toggleCollapsed} to={`${path}/products/add-product`}>
                Add Product
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key="demo">
              <NavLink onClick={toggleCollapsed} to={`${path}/users/demo`}>
                Demo
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key="add-attributes">
              <NavLink onClick={toggleCollapsed} to={`${path}/products/add-attributes`}>
                Add Attributes
              </NavLink>
            </Menu.Item>
            <Menu.Item key="productslist">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/product-list`}>
                Product List
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key="productsinventory">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/product-inventory`}>
                Product Inventory
              </NavLink>
            </Menu.Item> */}
            {/* <Menu.Item key="products">
          <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/products`}>
            Products Grid
          </NavLink>
        </Menu.Item> */}
          </SubMenu>
        ) : (
          ''
        )}
        {UserRole == 'VIEWER' ? (
          <SubMenu key="Products" icon={!topMenu && <FeatherIcon icon="package" />} title="Products">
            {/* <Menu.Item key="demo">
                  <NavLink onClick={toggleCollapsed} to={`${path}/users/demo`}>
                    Demo
                  </NavLink>
                </Menu.Item> */}

            <Menu.Item key="productslist">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/product-list`}>
                Product List
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key="productsinventory">
                  <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/product-inventory`}>
                    Product Inventory
                  </NavLink>
                </Menu.Item> */}
            {/* <Menu.Item key="products">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/products`}>
                Products Grid
              </NavLink>
            </Menu.Item> */}
          </SubMenu>
        ) : (
          ''
        )}

        {currentStoreModules['THEMES'] !== undefined ? (
          <SubMenu key="Themes" icon={!topMenu && <FeatherIcon icon="image" />} title="Themes">
            {/* <Menu.Item key="add-banners">
              <NavLink onClick={toggleCollapsed} to={`${path}/banners/add-banners`}>
                Add Banner
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key="banners-list">
              <NavLink onClick={toggleCollapsed} to={`${path}/banners/banners-list`}>
                Banners
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key="logo-list">
              <NavLink onClick={toggleCollapsed} to={`${path}/banners/banners-list`}>
                Logo
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key="headers-list">
              <NavLink onClick={toggleCollapsed} to={`${path}/banners/custom-header`}>
                Headers
              </NavLink>
            </Menu.Item>
            <Menu.Item key="script-list">
              <NavLink onClick={toggleCollapsed} to={`${path}/banners/custom-footer`}>
                Footer
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}

        {/* {currentStoreModules['STORE_MODULES'] !== undefined ? (
          <SubMenu key="modules" icon={!topMenu && <FeatherIcon icon="pocket" />} title="Store Modules">
            <Menu.Item key="store-modules">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/store-modules`}>
                Store Modules
              </NavLink>
            </Menu.Item>
            <Menu.Item key="moduleslist">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/moduleslist`}>
                Modules List
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )} */}

        {currentStoreModules['SUBSCRIPTIONS'] !== undefined ? (
          <SubMenu key="subscriptions" icon={!topMenu && <FeatherIcon icon="gift" />} title="Subscriptions">
            <Menu.Item key="subscription-plans">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/subscription-plans`}>
                Plans
              </NavLink>
            </Menu.Item>
            <Menu.Item key="subscribedusers">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/subscribedusers`}>
                Users
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}

        {currentStoreModules['ORDERS'] !== undefined ? (
          // <Menu.Item
          //   icon={
          //     !topMenu && (
          //       <NavLink className="menuItem-iocn" to={`${path}/ecommerce/orders`}>
          //         <FeatherIcon icon="list" />
          //       </NavLink>
          //     )
          //   }
          //   key="orders"
          // >
          //   <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/orders`}>
          //     Orders
          //   </NavLink>
          // </Menu.Item>
          <>
            {UserRole != 'SUPER_ADMIN' ? (
              <>
                {/* <p className="countmsg" id="ordercount-restaurant">
                  0
                </p> */}
                <Menu.Item key="orders" icon={!topMenu && <FeatherIcon icon="list" />}>
                  <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/orders`} className="storeorder">
                    {/* <FeatherIcon icon="circle" className="notification-dot"/> */}
                    {/* <div className="circleicon"> </div>&nbsp;  */}
                    <p className="messagecount" id="ordercount-restaurant">
                      0
                    </p>
                    {/* <br /> */}
                    Orders{' '}
                  </NavLink>
                </Menu.Item>
              </>
            ) : (
              <SubMenu
                key="orders"
                className="storeordermain"
                icon={!topMenu && <FeatherIcon icon="list" />}
                title="Orders"
              >
                <Menu.Item key="Restuarant">
                  <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/orders`} className="storeorder">
                    {/* <FeatherIcon icon="circle" className="notification-dot"/> */}
                    Restuarant Order
                  </NavLink>
                </Menu.Item>

                <>
                  <Menu.Item key="Grocery">
                    <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/grocery-orders`}>
                      Grocery Orders
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="Delivery">
                    <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/delivery-orders`}>
                      Package Orders
                    </NavLink>
                  </Menu.Item>
                </>
              </SubMenu>
            )}
          </>
        ) : (
          ''
        )}

        {currentStoreModules['PAYMENTS'] !== undefined ? (
          <SubMenu key="payments" icon={!topMenu && <FeatherIcon icon="dollar-sign" />} title="Payments">
            <Menu.Item key="transactions">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/transactions`}>
                Transactions
              </NavLink>
            </Menu.Item>
            <Menu.Item key="refunds">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/refunds`}>
                Refunds
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}

        {currentStoreModules['INVOICES'] !== undefined ? (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/ecommerce/invoicelist`}>
                  <FeatherIcon icon="file" />
                </NavLink>
              )
            }
            key="invoicelist"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/invoicelist`}>
              Invoices
            </NavLink>
          </Menu.Item>
        ) : (
          ''
        )}

        {UserRole == 'SUPER_ADMIN' ? (
          <>
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/ecommerce/pages`}>
                    <FeatherIcon icon="file-plus" />
                  </NavLink>
                )
              }
              key="pages"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/pages`}>
                Pages
              </NavLink>
            </Menu.Item>

            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/ecommerce/contact`}>
                    <FeatherIcon icon="phone" style={{ color: '#fff' }} />
                  </NavLink>
                )
              }
              key="contact"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/contact`}>
                Contact Us
              </NavLink>
            </Menu.Item>
          </>
        ) : (
          ''
        )}

        {currentStoreModules['REPORTS'] !== undefined && UserRole != 'DELIVERY_ADMIN' ? (
          <SubMenu key="reports" icon={!topMenu && <FeatherIcon icon="pie-chart" />} title="Reports">
            <Menu.Item key="payouts">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/payouts`}>
                Payouts
              </NavLink>
            </Menu.Item>
            <Menu.Item key="performance">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/performance`}>
                Performance
              </NavLink>
            </Menu.Item>
            <Menu.Item key="visitors">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/visitors`}>
                Visitors
              </NavLink>
            </Menu.Item>
            <Menu.Item key="sales">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/reports`}>
                Sales
              </NavLink>
            </Menu.Item>
            <Menu.Item key="transactions1">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/transaction`}>
                Transactions
              </NavLink>
            </Menu.Item>

            <Menu.Item key="ticket">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/ticket`}>
                Ticket
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key="cart_revenue">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/cart_revenue`}>
                Cart Revenue
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key="dispute">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/dispute`}>
                Dispute
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}

        {UserRole == 'DELIVERY_ADMIN' ? (
          <SubMenu key="reports" icon={!topMenu && <FeatherIcon icon="pie-chart" />} title="Reports">
            <Menu.Item key="payouts">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/payouts`}>
                Payouts
              </NavLink>
            </Menu.Item>
            <Menu.Item key="performance">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/performance`}>
                Performance
              </NavLink>
            </Menu.Item>
            <Menu.Item key="visitors">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/visitors`}>
                Visitors
              </NavLink>
            </Menu.Item>

            <Menu.Item key="transactions1">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/transaction`}>
                Transactions
              </NavLink>
            </Menu.Item>
            <Menu.Item key="ticket">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/ticket`}>
                Ticket
              </NavLink>
            </Menu.Item>
            <Menu.Item key="cart_revenue">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/cart_revenue`}>
                Cart Revenue
              </NavLink>
            </Menu.Item>
            <Menu.Item key="dispute">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/dispute`}>
                Dispute
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}

        {currentStoreModules['SETTINGS'] !== undefined ? (
          <SubMenu key="settings" icon={!topMenu && <FeatherIcon icon="settings" />} title="Settings">
            {/* <Menu.Item key="apikeys">
              <NavLink onClick={toggleCollapsed} to={`${path}/components/apikeys`}>
                API Keys
              </NavLink>
            </Menu.Item> */}
            {/* <Menu.Item key="two">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/payment-details`}>
                Payment Details
              </NavLink>
            </Menu.Item> */}
            {/* <Menu.Item key="three">
              <NavLink onClick={toggleCollapsed} to={`${path}/components/upload`}>
                Logo Add/Update
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key="five">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/tax-list`}>
                Taxes
              </NavLink>
            </Menu.Item>
            <Menu.Item key="six">
              <NavLink onClick={toggleCollapsed} to={`${path}/settings`}>
                Profile Settings
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key="privacypolicy">
              <NavLink onClick={toggleCollapsed} to={`${path}/components/privacypolicy`}>
                Privacy Policy
              </NavLink>
            </Menu.Item> */}
            {/* <Menu.Item key="terms&condition">
              <NavLink onClick={toggleCollapsed} to={`${path}/components/terms&condition`}>
                Terms & Conditions
              </NavLink>
            </Menu.Item> */}
            {/* <Menu.Item key="refundpolicy">
              <NavLink onClick={toggleCollapsed} to={`${path}/components/refundpolicy`}>
                Refund Policy
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key="systemsetting">
              <NavLink onClick={toggleCollapsed} to={`${path}/components/systemsettings`}>
                System Settings
              </NavLink>
            </Menu.Item>
            <Menu.Item key="configuration">
              <NavLink onClick={toggleCollapsed} to={`${path}/components/configurations`}>
                Configuration
              </NavLink>
            </Menu.Item>
            <Menu.Item key="support">
              <NavLink onClick={toggleCollapsed} to={`${path}/components/supports`}>
                Support
              </NavLink>
            </Menu.Item>
            <Menu.Item key="paymentmethod">
              <NavLink onClick={toggleCollapsed} to={`${path}/components/paymentmethods`}>
                Payment Method
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}
        {/* {!topMenu && <p className="sidebar-nav-title">Stores</p>} */}
        {currentStoreModules['CUSTOMERS'] !== undefined ? (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/ecommerce/customerslist`}>
                  <FeatherIcon icon="list" />
                </NavLink>
              )
            }
            key="customers"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/customerslist`}>
              Customers
            </NavLink>
          </Menu.Item>
        ) : (
          // <SubMenu key="customers" icon={!topMenu && <FeatherIcon icon="user" />} title="Customers">
          //   <Menu.Item key="addCustomer">
          //     <NavLink onClick={toggleCollapsed} to={`${path}/users/add-customer`}>
          //       Add Customer
          //     </NavLink>
          //   </Menu.Item>
          //   <Menu.Item key="customerslist">
          //     <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/customerslist`}>
          //       Customers
          //     </NavLink>
          //   </Menu.Item>
          // </SubMenu>
          ''
        )}

        {currentStoreModules['DELIVERY_PARTNERS'] !== undefined ? (
          <SubMenu key="delivery" icon={!topMenu && <FeatherIcon icon="truck" />} title="Delivery Partners">
            {/* <Menu.Item key="add">
              <NavLink onClick={toggleCollapsed} to={`${path}/users/add-partner`}>
                Add Delivery Partner
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key="lsit">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/partnerlist`}>
                User
              </NavLink>
            </Menu.Item>

            <Menu.Item>
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/partners-roles`}>
                Roles
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}

        {currentStoreModules['TICKETS'] !== undefined && UserRole != 'VIEWER' ? (
          <SubMenu key="ticket" icon={!topMenu && <FeatherIcon icon="file-text" />} title="Ticket">
            <Menu.Item key="tickets">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/tickets`}>
                Generated Tickets
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}
        {UserRole == 'VIEWER' ? (
          <SubMenu key="ticket" icon={!topMenu && <FeatherIcon icon="file-text" />} title="Ticket">
            <Menu.Item key="tickets">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/tickets`}>
                Generated Tickets
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}
        {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/ecommerce/tickets`}>
              <FeatherIcon icon="" />
            </NavLink>
          )
        }
        key="tickets"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/tickets`}>
          Tickets
        </NavLink>
      </Menu.Item> */}
        {currentStoreModules['OFFERS'] !== undefined ? (
          <SubMenu key="offers" icon={!topMenu && <FeatherIcon icon="percent" />} title="Offers">
            <Menu.Item key="discounts">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/discounts`}>
                Discounts
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}

        {currentStoreModules['STATUS'] !== undefined ? (
          <SubMenu key="status" icon={!topMenu && <FeatherIcon icon="file-text" />} title="Status">
            <Menu.Item key="status">
              <NavLink onClick={toggleCollapsed} to={`${path}/ecommerce/satatuslist`}>
                Manage Status
              </NavLink>
            </Menu.Item>
          </SubMenu>
        ) : (
          ''
        )}
      </>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  // events: propTypes.object,
};

export default MenuItems;
