import React, { lazy } from 'react';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { Route, useRouteMatch } from 'react-router-dom';
//import Transaction from '../../container/ecommerce/Transaction';

const Product = lazy(() => import('../../container/ecommerce/product/Products'));
const ProductList = lazy(() => import('../../container/ecommerce/ProductList'));
const ProductAdd = lazy(() => import('../../container/ecommerce/product/AddProduct'));
const ProductInventory = lazy(() => import('../../container/ecommerce/ProductInventory'));
const ProductEdit = lazy(() => import('../../container/ecommerce/product/EditProduct'));
const ProductDetails = lazy(() => import('../../container/ecommerce/product/ProductDetails'));

const Invoice = lazy(() => import('../../container/ecommerce/Invoice'));
const InvoiceList = lazy(() => import('../../container/ecommerce/InvoiceList'));
const Messages = lazy(() => import('../../container/ecommerce/Messages'));
const AddInvoice = lazy(() => import('../../container/ecommerce/AddInvoice'));
const ViewInvoice = lazy(() => import('../../container/ecommerce/ViewInvoice'));
const EditInvoice = lazy(() => import('../../container/ecommerce/EditInvoice'));
const EditInvoice1 = lazy(() => import('../../container/ecommerce/EditInvoice1'));
const Pages = lazy(() => import('../../container/ecommerce/Pages'));
const AddPages = lazy(() => import('../../container/ecommerce/AddPages'));
const ViewPages = lazy(() => import('../../container/ecommerce/ViewPages'));
const EditPages = lazy(() => import('../../container/ecommerce/EditPages'));

const Orders = lazy(() => import('../../container/ecommerce/Orders'));
const AddOrder = lazy(() => import('../../container/ecommerce/AddOrder'));
const ViewOrder = lazy(() => import('../../container/ecommerce/ViewOrder'));
const EditOrder = lazy(() => import('../../container/ecommerce/EditOrder'));

const DeliveryOrder = lazy(() => import('../../container/ecommerce/DeliveryOrder'));
const AddDeliveryOrder = lazy(() => import('../../container/ecommerce/AddDeliveryOrder'));
const ViewDeliveryOrder = lazy(() => import('../../container/ecommerce/ViewDeliveryOrder'));
const EditDeliveryOrder = lazy(() => import('../../container/ecommerce/EditDeliveryOrder'));
const GroceryOrder = lazy(() => import('../../container/ecommerce/GroceryOrder'));

const Categories = lazy(() => import('../../container/ecommerce/Categories'));
const AddCategory = lazy(() => import('../../container/ecommerce/AddCategory'));
const EditCategory = lazy(() => import('../../container/ecommerce/EditCategory'));
const ViewCategory = lazy(() => import('../../container/ecommerce/ViewCategory'));

const SubCategories = lazy(() => import('../../container/ecommerce/SubCategories'));
const AddSubCategories = lazy(() => import('../../container/ecommerce/AddSubCategories'));
const EditSubCategories = lazy(() => import('../../container/ecommerce/EditSubCategories'));
const ViewSubCategories = lazy(() => import('../../container/ecommerce/ViewSubCategories'));

const Reports = lazy(() => import('../../container/ecommerce/Reports'));
const AddReports = lazy(() => import('../../container/ecommerce/AddReports'));
const Performance = lazy(() => import('../../container/ecommerce/Performance'));
const Payouts = lazy(() => import('../../container/ecommerce/Payouts'));
const Visitors = lazy(() => import('../../container/ecommerce/Visitors'));
const Transaction = lazy(() => import('../../container/ecommerce/Transaction'));
const Ticket = lazy(() => import('../../container/ecommerce/Ticket'));
const Cart_Revenue = lazy(() => import('../../container/ecommerce/Cart_Revenue'));
// const Dispute = lazy(() => import('../../container/ecommerce/Dispute'));

const AddSubscriptionPlan = lazy(() => import('../../container/ecommerce/AddSubscriptionPlan'));
const EditSubscriptionPlan = lazy(() => import('../../container/ecommerce/EditSubscriptionPlan'));
const SubscriptionPlans = lazy(() => import('../../container/ecommerce/SubscriptionPlans'));

const SubscribedUsers = lazy(() => import('../../container/ecommerce/SubscribedUsers'));
const AddSubscribedUser = lazy(() => import('../../container/ecommerce/AddSubscribedUser'));
const EditSubscribedUser = lazy(() => import('../../container/ecommerce/EditSubscribedUser'));
const ViewSubscribedUser = lazy(() => import('../../container/ecommerce/ViewSubscribedUser'));

const PaymentDetails = lazy(() => import('../../container/ecommerce/PaymentDetails'));

const TaxList = lazy(() => import('../../container/ecommerce/TaxList'));
const AddTax = lazy(() => import('../../container/ecommerce/AddTax'));
const ViewTax = lazy(() => import('../../container/ecommerce/ViewTax'));
const EditTax = lazy(() => import('../../container/ecommerce/EditTax'));

const Refunds = lazy(() => import('../../container/ecommerce/Refunds'));
const AddRefund = lazy(() => import('../../container/ecommerce/AddRefund'));
const ViewRefund = lazy(() => import('../../container/ecommerce/ViewRefund'));
const EditRefund = lazy(() => import('../../container/ecommerce/EditRefund'));

const Transactions = lazy(() => import('../../container/ecommerce/Transactions'));
const AddTransaction = lazy(() => import('../../container/ecommerce/AddTransaction'));
const ViewTransaction = lazy(() => import('../../container/ecommerce/ViewTransaction'));
const EditTransaction = lazy(() => import('../../container/ecommerce/EditTransaction'));

const Contact = lazy(() => import('../../container/ecommerce/Contact'));
const AddContact = lazy(() => import('../../container/ecommerce/AddContact'));
const ViewContact = lazy(() => import('../../container/ecommerce/ViewContact'));
// const EditContact = lazy(() => import('../../container/ecommerce/EditContact'));
const Tickets = lazy(() => import('../../container/ecommerce/Tickets'));
const AddTicket = lazy(() => import('../../container/ecommerce/AddTicket'));
const ViewTicket = lazy(() => import('../../container/ecommerce/ViewTicket'));
const EditTicket = lazy(() => import('../../container/ecommerce/EditTicket'));

const StatusList = lazy(() => import('../../container/ecommerce/StatusList'));
const AddStatus = lazy(() => import('../../container/ecommerce/AddStatus'));
const ViewStatus = lazy(() => import('../../container/ecommerce/ViewStatus'));
const EditStatus = lazy(() => import('../../container/ecommerce/EditStatus'));

const FaqList = lazy(() => import('../../container/ecommerce/FaqList'));
const AddFaq = lazy(() => import('../../container/ecommerce/AddFaq'));
const ViewFaq = lazy(() => import('../../container/ecommerce/ViewFaq'));
const EditFaq = lazy(() => import('../../container/ecommerce/EditFaq'));

const EntityList = lazy(() => import('../../container/ecommerce/EntityList'));
const AddEntity = lazy(() => import('../../container/ecommerce/AddEntity'));
const ViewEntity = lazy(() => import('../../container/ecommerce/ViewEntity'));
const EditEntity = lazy(() => import('../../container/ecommerce/EditEntity'));

const EntityLists = lazy(() => import('../../container/ecommerce/EntityLists'));
const AddEntityList = lazy(() => import('../../container/ecommerce/AddEntityList'));
const ViewEntityList = lazy(() => import('../../container/ecommerce/ViewEntityList'));
const EditEntityList = lazy(() => import('../../container/ecommerce/EditEntityList'));

const ExpenseList = lazy(() => import('../../container/ecommerce/ExpenseList'));
const AddExpense = lazy(() => import('../../container/ecommerce/AddExpense'));
const ViewExpense = lazy(() => import('../../container/ecommerce/ViewExpense'));
const EditExpense = lazy(() => import('../../container/ecommerce/EditExpense'));

const GlobalList = lazy(() => import('../../container/ecommerce/GlobalList'));
const AddGlobal = lazy(() => import('../../container/ecommerce/AddGlobal'));
const EditGlobal = lazy(() => import('../../container/ecommerce/EditGlobal'));

const Discounts = lazy(() => import('../../container/ecommerce/Discounts'));
const AddDiscount = lazy(() => import('../../container/ecommerce/AddDiscount'));
const EditDiscount = lazy(() => import('../../container/ecommerce/EditDiscount'));
const ViewDiscount = lazy(() => import('../../container/ecommerce/ViewDiscount'));

const CustomersList = lazy(() => import('../../container/ecommerce/CustomersList'));
const PartnerList = lazy(() => import('../../container/ecommerce/PartnerList'));
const PartnersRoles = lazy(() => import('../../container/ecommerce/PartnersRoles'));

const StoreModules = lazy(() => import('../../container/ecommerce/StoreModules'));
const AddStoreModule = lazy(() => import('../../container/ecommerce/AddStoreModule'));

const ModulesList = lazy(() => import('../../container/ecommerce/ModulesList'));
const AddModuleList = lazy(() => import('../../container/ecommerce/AddModuleList'));
const ModuleView = lazy(() => import('../../container/ecommerce/ModuleView'));
const ModuleEdit = lazy(() => import('../../container/ecommerce/ModuleEdit'));

const Sellers = lazy(() => import('../../container/ecommerce/Sellers'));
const Cart = lazy(() => import('../../container/ecommerce/Cart'));

const EcommerceRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/products`} component={Product} />
      <Route path={`${path}/product-list`} component={ProductList} />
      <Route exact path={`${path}/add-product`} component={ProductAdd} />
      <Route exact path={`${path}/product-inventory`} component={ProductInventory} />
      <Route exact path={`${path}/edit-product`} component={ProductEdit} />
      <Route exact path={`${path}/productDetails/:id`} component={ProductDetails} />

      <Route exact path={`${path}/invoice/:id`} component={Invoice} />
      <Route exact path={`${path}/invoicelist`} component={InvoiceList} />
      <Route exact path={`${path}/messages`} component={Messages} />
      <Route exact path={`${path}/pages`} component={Pages} />
      <Route exact path={`${path}/add-pages`} component={AddPages} />
      <Route exact path={`${path}/view-pages/:slug`} component={ViewPages} />
      <Route exact path={`${path}/edit-pages/:slug`} component={EditPages} />
      <Route exact path={`${path}/add-invoice`} component={AddInvoice} />
      <Route exact path={`${path}/view-invoice/:id`} component={ViewInvoice} />
      <Route exact path={`${path}/edit-invoice/:id`} component={EditInvoice} />
      <Route exact path={`${path}/edit-invoice1/:id`} component={EditInvoice1} />

      <Route exact path={`${path}/orders`} component={Orders} />
      <Route exact path={`${path}/add-order`} component={AddOrder} />
      <Route exact path={`${path}/view-order/:id`} component={ViewOrder} />
      <Route exact path={`${path}/edit-order/:id`} component={EditOrder} />

      <Route exact path={`${path}/delivery-orders`} component={DeliveryOrder} />
      <Route exact path={`${path}/grocery-orders`} component={GroceryOrder} />
      <Route exact path={`${path}/add-delivery-order`} component={AddDeliveryOrder} />
      <Route exact path={`${path}/view-delivery-order/:id`} component={ViewDeliveryOrder} />
      <Route exact path={`${path}/edit-delivery-order/:id`} component={EditDeliveryOrder} />

      <Route exact path={`${path}/categories`} component={Categories} />
      <Route path={`${path}/add-category`} component={AddCategory} />
      <Route path={`${path}/edit-category/:id`} component={EditCategory} />
      <Route path={`${path}/view-category/:id`} component={ViewCategory} />

      <Route exact path={`${path}/reports`} component={Reports} />
      <Route exact path={`${path}/reports/add`} component={AddReports} />
      <Route exact path={`${path}/visitors`} component={Visitors} />
      <Route exact path={`${path}/performance`} component={Performance} />
      <Route exact path={`${path}/payouts`} component={Payouts} />

      <Route exact path={`${path}/subcategories`} component={SubCategories} />
      <Route path={`${path}/add-subcategories`} component={AddSubCategories} />
      <Route path={`${path}/edit-subcategories`} component={EditSubCategories} />
      <Route path={`${path}/view-subcategories`} component={ViewSubCategories} />

      <Route exact path={`${path}/subscribedusers`} component={SubscribedUsers} />
      <Route exact path={`${path}/add-subscribed-user`} component={AddSubscribedUser} />
      <Route exact path={`${path}/edit-subscribed-user/:id`} component={EditSubscribedUser} />
      <Route exact path={`${path}/view-subscribed-user/:id`} component={ViewSubscribedUser} />

      <Route exact path={`${path}/subscription-plans`} component={SubscriptionPlans} />
      <Route exact path={`${path}/add-subscription-plan`} component={AddSubscriptionPlan} />
      <Route exact path={`${path}/edit-subscription-plan/:id`} component={EditSubscriptionPlan} />

      <Route exact path={`${path}/payment-details`} component={PaymentDetails} />

      <Route exact path={`${path}/tax-list`} component={TaxList} />
      <Route exact path={`${path}/add-tax`} component={AddTax} />
      <Route exact path={`${path}/view-tax/:id`} component={ViewTax} />
      <Route exact path={`${path}/edit-tax/:id`} component={EditTax} />

      <Route exact path={`${path}/refunds`} component={Refunds} />
      <Route exact path={`${path}/add-refund`} component={AddRefund} />
      <Route exact path={`${path}/view-refund/:id`} component={ViewRefund} />
      <Route exact path={`${path}/edit-refund/:id`} component={EditRefund} />

      <Route exact path={`${path}/transactions`} component={Transactions} />
      <Route exact path={`${path}/add-transaction`} component={AddTransaction} />
      <Route exact path={`${path}/view-transaction/:id`} component={ViewTransaction} />
      <Route exact path={`${path}/edit-transaction/:id`} component={EditTransaction} />
      <Route exact path={`${path}/transaction`} component={Transaction} />
      <Route exact path={`${path}/ticket`} component={Ticket} />
      <Route exact path={`${path}/cart_revenue`} component={Cart_Revenue} />
      {/* <Route exact path={`${path}/dispute`} component={Dispute} /> */}

      <Route exact path={`${path}/tickets`} component={Tickets} />
      <Route exact path={`${path}/contact`} component={Contact} />
      <Route exact path={`${path}/add-contact`} component={AddContact} />
      <Route exact path={`${path}/view-contact/:id`} component={ViewContact} />
      {/* <Route exact path={`${path}/edit-contact/:id`} component={EditContact} /> */}
      <Route exact path={`${path}/add-ticket`} component={AddTicket} />
      <Route exact path={`${path}/view-ticket/:id`} component={ViewTicket} />
      <Route exact path={`${path}/edit-ticket/:id`} component={EditTicket} />

      <Route exact path={`${path}/satatuslist`} component={StatusList} />
      <Route exact path={`${path}/add-status`} component={AddStatus} />
      <Route exact path={`${path}/view-status/:id`} component={ViewStatus} />
      <Route exact path={`${path}/edit-status/:id`} component={EditStatus} />

      <Route exact path={`${path}/faqlist`} component={FaqList} />
      <Route exact path={`${path}/add-faq`} component={AddFaq} />
      <Route exact path={`${path}/view-faq/:id`} component={ViewFaq} />
      <Route exact path={`${path}/edit-faq/:id`} component={EditFaq} />

      {/* <Route exact path={`${path}/entitylist`} component={EntityList} />
      <Route exact path={`${path}/add-entity`} component={AddEntity} />
      <Route exact path={`${path}/view-entity/:id`} component={ViewEntity} />
      <Route exact path={`${path}/edit-entity/:id`} component={EditEntity} /> */}

      <Route exact path={`${path}/entitylists`} component={EntityLists} />
      <Route exact path={`${path}/add-entitylist`} component={AddEntityList} />
      <Route exact path={`${path}/view-entitylist/:id`} component={ViewEntityList} />
      <Route exact path={`${path}/edit-entitylist/:id`} component={EditEntityList} />

      <Route exact path={`${path}/expenselist`} component={ExpenseList} />
      <Route exact path={`${path}/add-expense`} component={AddExpense} />
      <Route exact path={`${path}/view-expense/:id`} component={ViewExpense} />
      <Route exact path={`${path}/edit-expense/:id`} component={EditExpense} />

      <Route exact path={`${path}/globallist`} component={GlobalList} />
      <Route exact path={`${path}/add-global`} component={AddGlobal} />
      <Route exact path={`${path}/edit-global/:id`} component={EditGlobal} />

      <Route exact path={`${path}/discounts`} component={Discounts} />
      <Route exact path={`${path}/add-discount`} component={AddDiscount} />
      <Route exact path={`${path}/edit-discount/:id`} component={EditDiscount} />
      <Route exact path={`${path}/view-discount/:id`} component={ViewDiscount} />

      <Route exact path={`${path}/customerslist`} component={CustomersList} />
      <Route exact path={`${path}/partnerlist`} component={PartnerList} />
      <Route exact path={`${path}/partners-roles`} component={PartnersRoles} />

      <Route exact path={`${path}/moduleslist`} component={ModulesList} />
      <Route exact path={`${path}/addmodules`} component={AddModuleList} />
      <Route exact path={`${path}/module-view/:id`} component={ModuleView} />
      <Route exact path={`${path}/module-edit/:id`} component={ModuleEdit} />

      <Route exact path={`${path}/store-modules`} component={StoreModules} />
      <Route exact path={`${path}/add-store-module`} component={AddStoreModule} />

      <Route exact path={`${path}/sellers`} component={Sellers} />
      <Route path={`${path}/cart`} component={Cart} />
    </Switch>
  );
};

export default EcommerceRoute;
