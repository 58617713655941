import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { Helmet } from 'react-helmet';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  DatePicker,
  Select,
  Radio,
  InputNumber,
  TimePicker,
  Upload,
  Checkbox,
  notification,
  Image,
} from 'antd';
import moment from 'moment';
import { Switch, Route, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { AddUser } from './style';
import authorizes from '../../../src/static/img/authorized.png';
import { post_api_request, api_url, get_api_request } from '../../helpers/Api.js';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import ReviewIcon from '../../static/img/review-icon-29.png';
import StaticImage from '../../static/img/default_image.jpg';
import { headers } from '../../helpers/variables';
import Cookies from 'js-cookie';
import imageUploadSave from '../../helpers/uploadImage';
import Geocode from 'react-geocode';
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import GoogleMapReact from 'google-map-react';
// import { vAlign } from '@progress/kendo-drawing/dist/npm/alignment';
const { imageRender } = require('../../helpers/renderImage');
const { encrypt, decrypt } = require('../../helpers/encryption-decryption');
const { encrypttheid, decodetheid } = require('../../helpers/encode-decode');

const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

var ModuleName = 'ADDNEWSTORE';
const AddNewStore = () => {
  var centerlocation = { lat: -33.8688, lng: 151.2195 };
  const history = useHistory();
  //const google = window.google;
  var latlng;

  const { path } = useRouteMatch();

  const [cities, setCitties] = useState();
  const [address, setaddress] = useState();
  const [splitDays, setSplitDays] = useState(null);
  const [pickup, setPickup] = useState(false);
  const [allDay, setAllDay] = useState(false);
  const [formataddr, setformataddr] = useState();
  const [formData, setformData] = useState({
    address_line_1: '',
    address_line_2: '',
    country: '',
    city: '',
    state: '',
    active: '',
  });

  const [OperationDays, setOperationDays] = useState();
  // const [arrayDataStoreType, setArrayDataStoreType] = useState(null);
  // const [arraydatapostions, setarraydatapostions] = useState(null);
  const [arraydataTaxByCountry, setarraydataTaxByCountry] = useState(null);
  const [simpleStoreGalleryImages, setSimpleStoreGalleryImagesImages] = useState([]);
  const [simpleStoreGalleryimagesURL, setSimpleStoreGalleryImagesURL] = useState([]);

  const [arrayStoreUser, setArrayStoreUser] = useState(null);
  const [arrayStoreUserDetail, setArrayStoreUserDetail] = useState(null);
  const [arraydatatype, setarraydatatype] = useState(null);
  const [arraydatastate, setarraydatastate] = useState(null);
  const [arraydatacountry, setarraydatacountry] = useState(null);
  const [country, setcountry] = useState();
  const [checkbox, setCheckbox] = useState();
  const [checdining, setCheckeddining] = useState();
  const [userDetail, setUserDetail] = useState({
    email: '',
    phone: '',
  });
  const [userName, setUserName] = useState({
    fullname: '',
    id: '',
  });
  const [isEnabled, setIsEnabled] = useState({
    active: '',
  });

  const [day, setday] = useState({ open_time: '' });
  var selectedMapAddress = [];
  // const format = 'HH:mm';
  // const { TextArea } = Input;
  const inputRef = useRef();
  var selectedMapAddress = [];
  var Array_of_Modules = [];
  var UserRole = [];
  var currentStoreModules = [];
  var value_of_Module = [];
  var getModule = [];
  // const [imageUrl, setImageURL] = useState(null);
  const [images, setImages] = useState([]);
  const [deliveryimageFront, setdeliveryimagefront] = useState([]);
  const [deliveryimageback, setdeliveryimageback] = useState([]);
  const [deliveryimageFrontURL, setdeliveryimagefrontURL] = useState([]);
  const [deliveryimagebackURL, setdeliveryimagebackURL] = useState([]);
  const [deliveryVehicleimageFront, setdeliveryVehicleimagefront] = useState([]);
  const [deliveryVehicleimageback, setdeliveryVehicleimageback] = useState([]);
  const [deliveryVehicleimageFrontURL, setdeliveryVehicleimagefrontURL] = useState([]);
  const [deliveryVehicleimagebackURL, setdeliveryVehicleimagebackURL] = useState([]);
  const [store_licence_image, setStore_licence_image] = useState([]);
  const [store_licence_imageURL, setStore_licence_imageURL] = useState([]);
  const [store_ValidID_image, setStore_ValidID_image] = useState([]);
  const [store_ValidID_imageURL, setStore_ValidID_imageURL] = useState([]);
  var search_address = formData.address_line_1 + ' ,' + formData.city;
  if (formData.city != '') {
    var search_address = formData.address_line_1 + ' ,' + formData.city;
  }
  if (formData.country != '') {
    var search_address = formData.address_line_1 + ' ,' + formData.city + ',' + formData.country;
  }

  var SelectedStoreID = sessionStorage.getItem('storeID'); //Get Selected Store ID
  const enc_user_detail = Cookies.get('UserDetail');
  var userDetail1 = decrypt(enc_user_detail);
  var currectStoreID = userDetail1?.currentstore?.store_id;
  var currectUserRoleID = userDetail1?.currentstore?.user_role_id;
  var userStore = userDetail1?.store;

  // const { Dragger } = Upload;
  const format = 'HH:mm';
  const { TextArea } = Input;

  const [arrayDataStoreType, setArrayDataStoreType] = useState(null);
  const [arraydatapostions, setarraydatapostions] = useState(null);
  const [storeResponse, setStoreResponse] = useState({});
  const [userID, setUserID] = useState();
  const [sbmt, setsbmt] = useState();
  const [ShowDeliverySection, setShowDeliverySection] = useState(false);
  const [DeliveryPartnerDetails, setDeliveryPartnerDetails] = useState({
    licence_number: '',
    vechicle_type: '',
    vechicle_colour: '',
    transport_mode: '',
    fuel_type: '',
    vechicle_number: '',
  });

  const [review, setReview] = useState(false);
  const [createdStoreID, setcreatedStoreID] = useState();
  //
  useEffect(() => {
    const enc_user_detail = Cookies.get('UserDetail');
    const response = decrypt(enc_user_detail);

    const userID = response?.users_id;
    setUserID(userID);
    if (response?.login == true) {
      // const userInfo = response.data.store;
      let storeInfo;
      let dd = response?.store;

      if (dd && dd.length > 0) {
        storeInfo = response?.store?.[0].store_status_name;
      } else {
        storeInfo = response?.store;
      }

      if (storeInfo === 'PENDING') {
        setReview(true);
      }
      setStoreResponse({ storeInfo });
    }
    //getting storetypes++++++++++++++++++++++++++++++++++++++++++++STARTS
    async function getStoreTypes() {
      const payload = { request: 'store' };
      await Axios.post(domainpath + '/storetype/all', payload, { headers }).then(response => {
        if (response.status === 200) {
          const storetypedata = response.data.responsedata.storetypes;

          //make array of names of store types+++++++++++++++++
          const dataArray = storetypedata.map(item => {
            return { id: item.id, name: item.name };
          });

          setArrayDataStoreType(dataArray);

          //+++++++++++++++++++++++++++++++++++++++++++++++++++
        }
      });
    }
    getStoreTypes();

    //get User Role dynamicaly ----
    userStore?.map(item => {
      if (currectUserRoleID == item.user_role_id) {
        return (UserRole = item.user_role);
      }
    });
    // if (UserRole == 'super_admin') {
    //   async function getUserDataByID() {
    //     const url = api_url.get_all_storeusers;
    //     const response = await get_api_request(url, headers);
    //     if (response.status === 200) {
    //       const userdata = response.data.responsedata.users;
    //       //make array of names of store types+++++++++++++++++
    //       const dataArray = userdata?.map(item => {
    //         const userdID = decodetheid(item.id);

    //         setUserName({ ...userDetail, fullname: item.fullname, id: userdID });
    //         return { id: item.id, name: item.fullname };
    //       });
    //       setArrayStoreUser(dataArray);
    //     } else {
    //       setArrayStoreUser(null);
    //     }
    //   }
    //   getUserDataByID();
    // } else {
    async function getUserDataByID(userID) {
      const url = api_url.user_single + userID;
      const response = await get_api_request(url, headers);

      if (response.status === 200) {
        const userdata = response.data.responsedata.user;

        const dataArray = userdata?.map(item => {
          const userdID = decodetheid(item.id);

          //setUserName({ ...userDetail, fullname: item.fullname, id: userdID });
          return { id: item.id, name: item.first_Name + '' + item.last_Name };
        });
        setArrayStoreUser(dataArray);
      } else {
        setArrayStoreUser(null);
      }
      // });
    }
    getUserDataByID(userID);
    // }

    async function getCountry() {
      await Axios.get(domainpath + '/country/', { headers }).then(response => {
        if (response.status === 200) {
          const storetypedata = response.data.responsedata.countries;
          setcountry(storetypedata);
          const dataArray = storetypedata.map(item => {
            return { id: item.id, name: item.full_name };
          });
          setarraydatacountry(dataArray);
        }
      });
    }
    getCountry();
    //getting storetypes++++++++++++++++++++++++++++++++++++++++++++ENDS
  }, []);

  async function citynamebystate(event) {
    form.resetFields(['city']);

    const selectedValue = { id: event };

    async function getcitybystateid(id) {
      await Axios.get(domainpath + `/country/state/${id}/city`, { headers }).then(response => {
        if (response.status === 200) {
          const citidata = response.data.responsedata.cities;

          setCitties(citidata);
        }
      });
    }
    getcitybystateid(event);
    //
  }
  async function storetypeselectedToGetstate(event) {
    async function getStoresByStoreType(id) {
      await Axios.get(domainpath + `/country/${id}/state`, { headers }).then(response => {
        if (response.status === 200) {
          const storesdata = response.data.responsedata.states;

          const dataArray = storesdata.map(item => {
            return { id: item.id, name: item.name };
          });
          setarraydatastate(dataArray);
        }
      });
    }
    async function getAllTaxesByCountry(id) {
      await Axios.get(domainpath + `/taxes/country/${id}`, { headers }).then(response => {
        if (response.status === 200) {
          const taxesbycountry = response.data.responsedata.taxes;
          const dataArray = taxesbycountry.map(item => {
            return { id: item.id, name: item.tax_name };
          });

          setarraydataTaxByCountry(dataArray);
        }
      });
    }
    getStoresByStoreType(event);
    getAllTaxesByCountry(event);
  }

  const [active, setActive] = useState();
  const onChange = checked => {
    var status;
    if (checked == 1) {
      status = 1;
    } else {
      status = 0;
    }
    setActive(status);
  };
  const onChangeOpenTime = e => {};
  const onChangeCloseTime = e => {};

  const onChangePickup = e => {
    if (e.target.checked === true) {
      setPickup(0);
    } else if (e.target.checked === false) {
      setPickup(1);
    }
  };

  const handleChange = event => {
    if (event.target.value == true) {
      setIsEnabled('1');
    } else {
      setIsEnabled('0');
    }
  };
  const inputChange = event => {
    search_address = 'Moahli sector 75';

    setformData(event.target.value === '');
  };

  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++STARTS
  const [filevar, setFile] = useState(null);
  const url = domainpath + '/images/storefeatured';
  const gallery_url = domainpath + '/images/storegallery';
  const imageHandleChange = async e => {
    var fieldsname;
    var vfile;
    fieldsname = e.target.name;
    vfile = e.target.files;

    var updateimg = [];
    if (fieldsname == 'images') {
      var singleimage = imageRender(vfile);
      setImages(singleimage);

      await imageUploadSave(vfile, url)
        .then(resp => {
          setImageURL(resp?.url);
          setImageURLMeta(resp?.image_meta);
          console.log(resp);
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } else if (fieldsname == 'gallery_images') {
      var multipleimages = imageRender(vfile);

      setSimpleStoreGalleryImagesImages(prev => prev.concat(multipleimages));
      await imageUploadSave(vfile, gallery_url)
        .then(resp => {
          console.log(resp);
          var imageurl = domainpath + resp;
          // kk2.push(imageurl);
          setSimpleStoreGalleryImagesURL(prev => prev.concat(imageurl));
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    }
  };

  const imageHandleChangeDelivery = async e => {
    var fieldsname;
    var vfile;
    fieldsname = e.target.name;
    vfile = e.target.files;
    var updateimg = [];
    if (fieldsname == 'front_image') {
      var singleimages = imageRender(vfile);
      setdeliveryimagefront(singleimages);

      await imageUploadSave(vfile, gallery_url)
        .then(resp => {
          console.log(resp);
          const imageurl = domainpath + resp[0];
          setdeliveryimagefrontURL(imageurl);
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } else {
      var singleimages = imageRender(vfile);

      setdeliveryimageback(singleimages);
      await imageUploadSave(vfile, gallery_url)
        .then(resp => {
          const imageurl = domainpath + resp[0];
          setdeliveryimagebackURL(imageurl);
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    }
  };
  const imageHandleChangeDeliveryVehicle = async e => {
    var fieldsname;
    var vfile;
    fieldsname = e.target.name;
    vfile = e.target.files;
    var updateimg = [];
    if (fieldsname == 'vechicle_front_image') {
      var singleimages = imageRender(vfile);
      setdeliveryVehicleimagefront(singleimages);

      await imageUploadSave(vfile, gallery_url)
        .then(resp => {
          const imageurl = domainpath + resp[0];
          setdeliveryVehicleimagefrontURL(imageurl);
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } else {
      var singleimages = imageRender(vfile);

      setdeliveryVehicleimageback(singleimages);
      await imageUploadSave(vfile, gallery_url)
        .then(resp => {
          const imageurl = domainpath + resp[0];
          setdeliveryVehicleimagebackURL(imageurl);
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    }
  };
  const imageHandleChangeStoreLicence = async e => {
    var fieldsname;
    var vfile;
    fieldsname = e.target.name;
    vfile = e.target.files;
    var updateimg = [];
    if (fieldsname != 'validID_image') {
      var singleimages = imageRender(vfile);
      setStore_licence_image(singleimages);

      await imageUploadSave(vfile, gallery_url)
        .then(resp => {
          const imageurl = domainpath + resp[0];
          setStore_licence_imageURL(imageurl);
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } else {
      var singleimages = imageRender(vfile);

      setStore_ValidID_image(singleimages);
      await imageUploadSave(vfile, gallery_url)
        .then(resp => {
          const imageurl = domainpath + resp[0];
          setStore_ValidID_imageURL(imageurl);
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    }
  };
  const renderPictures = source => {
    return source.map(pictures => {
      return <Image src={pictures} key={pictures} />;
    });
  };
  const changeArea = e => {};

  const handledays = e => {
    setSplitDays(e);
  };
  const kk = (e, value, item) => {};

  const days = [
    {
      value: 'Monday',
      label: 'Monday',
    },
    {
      value: 'Tuesday',
      label: 'Tuesday',
    },
    {
      value: 'Wednesday',
      label: 'Wednesday',
    },
    {
      value: 'Thursday',
      label: 'Thursday',
    },
    {
      value: 'Friday',
      label: 'Friday',
    },
    {
      value: 'Saturday',
      label: 'Saturday',
    },
    {
      value: 'Sunday',
      label: 'Sunday',
    },
  ];

  const payment = [
    {
      value: 'Cash',
      label: 'Cash',
    },
    {
      value: 'Credit/Debit',
      label: 'Credit/Debit',
    },
    {
      value: 'NetBanking',
      label: 'NetBanking',
    },
  ];
  const handlePayment = e => {};

  const [form] = Form.useForm();

  async function getStatebyCountries(event) {
    async function getStoresByStoreType(id) {
      await Axios.get(domainpath + `/country/${id}/state`, { headers }).then(response => {
        if (response.status === 200) {
          const storesdata = response.data.responsedata.states;

          const dataArray = storesdata.map(item => {
            return { id: item.id, name: item.name };
          });
          setarraydatastate(dataArray);
        }
      });
    }
    getStoresByStoreType(event);
  }
  async function storetypeselectedToGetUserDetail(event) {
    form.resetFields(['email"']);
    const storetypeID = event;
    async function getUserdetailByName(storetypeID) {
      const url = api_url.get_user_bystoretypeID + storetypeID;
      const response = await get_api_request(url, headers);
      if (response.status === 200) {
        const userdetail = response.data?.responsedata?.userdata;

        const dataArray = userdetail?.map(item => {
          setUserDetail({
            email: item.email,
            phone: item.phone,
          });
          return { email: item.email, phone: item.phone };
        });
        setArrayStoreUserDetail(dataArray);
      } else {
        setArrayStoreUserDetail(null);
      }
      // });
    }
    getUserdetailByName(storetypeID);
  }
  const GetPosition = event => {
    setShowDeliverySection(event);

    async function getCategoryPositions(id) {
      await Axios.get(domainpath + `/productcategory/positions/storetype/${id}`, { headers }).then(response => {
        if (response.status === 200) {
          const positionsdata = response.data.responsedata.categorypositions;
          setarraydatapostions(positionsdata);
          const dataArray = positionsdata.map(item => {
            return item.position;
          });
          const positionsArray = [];
          for (let i = 1; i <= 100; i++) {
            positionsArray.push(i);
          }
          const filterArray = (arr1, arr2) => {
            const filtered = arr1.filter(el => {
              return arr2.indexOf(el) === -1;
            });
            return filtered;
          };
          const finalposition = filterArray(positionsArray, dataArray);
        }
      });
    }
    getCategoryPositions(event);
  };

  //======================map========================================starts
  async function storetypeselectedToGetstore(event) {
    form.resetFields(['store_name_id']);
    const selectedValue = { id: event };
    async function getStoresByStoreType(postID) {
      const ID = postID.id;
      const payload = { req: 'id' };
      await Axios.post(domainpath + `/store/type/${ID}`, payload, { headers }).then(response => {
        if (response.status === 200) {
          const storesdata = response.data.responsedata.stores;

          const dataArray = storesdata.map(item => {
            return { id: item.id, name: item.name };
          });
          setarraydatatype(dataArray);
        }
      });
    }
    getStoresByStoreType(selectedValue);
    //
  }
  function initAutocomplete() {
    const map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: -33.8688, lng: 151.2195 },
      zoom: 13,
      mapTypeId: 'roadmap',
    });

    // Create the search box and link it to the UI element.
    const input = document.getElementById('pac-input');
    const searchBox = new google.maps.places.SearchBox(input);

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds());
    });

    let markers = [];

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      const mouseevent = google.maps.LatLng;

      latlng = places?.[0]?.formatted_address;

      selectedMapAddress = places?.[0].address_components;

      selectedMapAddress?.map(item => {
        if (item.types == 'street_number') {
        }

        if (item.types[0] == 'administrative_area_level_1') {
          form.setFieldsValue({ state: item.long_name });
        }
        if (item.types[0] == 'sublocality_level_2' && item.types[1] == 'sublocality' && item.types[2] == 'political') {
          form.setFieldsValue({ address_line_2: item.long_name });
        }
        if (item.types[0] == 'neighborhood') {
          form.setFieldsValue({ address_line_1: item.long_name });
        }
        if (item.types[0] == 'country') {
          form.setFieldsValue({ country: item.long_name });
        }
        if (item.types[0] === 'postal_code') {
          form.setFieldsValue({ postal_code: item.long_name });
        }
        if (
          item.types[0] === 'locality' &&
          item.types[1] == 'political' &&
          item.types[2] == 'administrative_area_level_3'
        ) {
          form.setFieldsValue({ city: item.long_name });
        }
      });

      if (places.length == 0) {
        return;
      }
      markers.forEach(marker => {
        marker.setMap(null);
      });
      markers = [];

      const bounds = new google.maps.LatLngBounds();

      places.forEach(place => {
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry');
          return;
        }

        const icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        markers.push(
          new google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          }),
        );
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);

      const getservice = () => {
        const google = (window.google = window.google ? window.google : {});

        geocodeByAddress('Montevideo, Uruguay')
          .then(results => getLatLng(results[0]))
          .then(({ lat, lng }) => console.log('Successfully got latitude and longitude', { lat, lng }));
      };
      getservice();
    }),
      [];
  }
  window.initAutocomplete = initAutocomplete;

  var service;
  // console.log(service);

  //======================map========================================ends
  const [gg, setGG] = useState({
    Monday: '',
    Tuesday: '',
    Wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
  });
  const handleOpeningDays = e => {
    var temp = {
      Monday: '',
      Tuesday: '',
      Wednesday: '',
      Thursday: '',
      Friday: '',
      Saturday: '',
      Sunday: '',
    };
    e.map(item => {
      if (gg[item].length > 0) {
        temp[item] = gg[item];
      }
    });

    const remove_empty_keys = Object.entries(temp).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
    setOperationDays(remove_empty_keys);
    setSplitDays(e);
  };

  const handledOpeningDaysAndTime = (value, e, item) => {
    gg[item.item1] = e;

    const remove_empty_keys = Object.entries(gg).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
    setOperationDays(remove_empty_keys);
  };

  const onChangeChecbox = e => {
    setCheckbox(e.target.checked);
  };

  const onChangeChecbox2 = e => {
    setCheckeddining(e.target.checked);
  };

  //getting store positions++++++++++++++++++++++++++++++++++++++++++++STARTS
  const [storeID, setStoreID] = useState();
  async function storetypeselectedToGetForm(event) {
    // form.resetFields(['position']);
    // console.log(event);
    //  const storetypeID = { id: event };
    const storetypeID = event;
    //  console.log(storetypeID);
    setStoreID(storetypeID);
  }

  const [fileName, setFileName] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [imageUrlMeta, setImageURLMeta] = useState(null);
  const [deliveryimageURL, setdeliveryImageURL] = useState(null);
  const [disableButton, setDisableButton] = useState(true);

  const [selectedImages, setSelectedImages] = useState([]);

  const uploadFile = async e => {
    console.log(file);
    if (file === null) {
      notification.error({
        message: 'Please Select Image First!!',
      });
    } else {
      const formData = new FormData();
      formData.append('photo', file);
      console.log(formData);
      async function uploadphotofunc(url, formData) {
        await Axios.post(url, formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            token: 'Bearer hjskdhskjdhsjkdhskjdhskjdhskdhskjdhsdjksjhdsjkdsdks',
          },
        })
          .then(response => {
            console.log(response);
            console.log('AC');
            //console.log(response.data.success);
            if (response.status === 200 && response.data.success === 'Success') {
              // console.log('insideeeee');
              setImageURL(response.data.url);
              notification.success({
                message: 'Image Added Successfully',
              });
              setTimeout(() => {
                notification.destroy();
              }, 3000);
              //empty the images uploaded--starts
              setSelectedImages(null);
              //empty the images uploaded--starts
            } else {
              notification.error({
                message: 'Error in Image Upload!!',
              });
            }
          })
          .catch(err => {
            console.log('err', err);
          });
      }
      uploadphotofunc(url, formData);
    }
  };
  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++ENDS--Featured Image

  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++STARTS--Gallery Image--MULTIPLE
  const [filevar1, setFile1] = useState(null);
  const [imageURL1, setImageURL1] = useState(null);
  const [disableButton1, setDisableButton1] = useState(true);
  const url1 = domainpath + '/images/storegallery';

  const uploadFile1 = async e => {
    console.log(filevar1);
    if (filevar1.length > 0) {
      const formData = new FormData();
      [...filevar1].map(file => {
        formData.append('photo', file);
      });
      console.log(formData);

      //for single file working below code-----------------
      //   const formData = new FormData();
      //   formData.append('photo', file);
      //-----------------------------------------------------

      async function uploadphotofunc1(url1, formData) {
        await Axios.post(url1, formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            token: 'Bearer hjskdhskjdhsjkdhskjdhskjdhskdhskjdhsdjksjhdsjkdsdks',
          },
        })
          .then(response => {
            console.log(response);
            console.log('AC');
            //console.log(response.data.success);
            if (response.status === 200 && response.data.success === 'Success') {
              // console.log('insideeeee');
              // setImageURL1(response.data.url);
              notification.success({
                message: 'Image Added Successfully',
              });
              setTimeout(() => {
                notification.destroy();
              }, 3000);
              //console.log(response.data.url);
              const imageData = response.data.url;
              const images = imageData.toString();
              setImageURL1(images);
              //console.log(images);
              //empty the images uploaded--starts
              setSelectedImages1(null);
              //empty the images uploaded--starts
            } else {
              notification.error({
                message: 'Error in Image Upload!!',
              });
            }
          })
          .catch(err => {
            console.log('err', err);
          });
      }
      uploadphotofunc1(url1, formData);
    } else {
      notification.error({
        message: 'Please Select Images First!!',
      });
    }
  };

  const [selectedImages1, setSelectedImages1] = useState([]);

  const imageHandleChange1 = e => {
    setFile1(null);
    setImageURL1(null);
    setDisableButton1(true);
    //console.log('inside save');
    //  console.log(e.target.files);
    if (e.target.files.length > 0) {
      setFile1(e.target.files);
      //  setFileName1(e.target.files[0].name);
      setDisableButton1(false);
    } else {
      notification.error({
        message: 'Please Select Image!!',
      });
    }
    //console.log(e.target.files);
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map(file => URL.createObjectURL(file)); //to create a url path for images
      console.log(fileArray);

      setSelectedImages1(prev => prev.concat(fileArray));

      //setSelectedImages1(fileArray);
      Array.from(e.target.files).map(file => URL.revokeObjectURL(file));
    }
  };

  const renderPictures1 = source => {
    return source?.map((pictures, index) => {
      return (
        <>
          <Image src={pictures} key={pictures} />
          <Button
            type="danger"
            onClick={() => {
              setSelectedImages1(source.filter(e => e !== pictures));
              console.log(index);
              //remove array picture wd specific index ---STARTS
              //let dataimg = JSON.stringify(filevar)
              console.log(filevar1);
              const fileListArr1 = Array.from(filevar1);
              console.log(fileListArr1);
              fileListArr1.splice(index, 1); // here u remove the file
              console.log(fileListArr1);
              setFile1(fileListArr1);
              //console.log(filevar);
              //remove array picture wd specific index ---ENDS
            }}
          >
            X
          </Button>
        </>
      );
    });
  };

  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++ENDS--Gallery Image --MULTIPLE

  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++STARTS--Vehicle Image--MULTIPLE
  const [Vfilevar, setVFile] = useState(null);
  const [VImageURL, setVImageURL] = useState(null);
  const [VDisableButton, setVDisableButton] = useState(true);
  const urlV = domainpath + '/images/storegallery';

  const uploadFileV = async e => {
    console.log(Vfilevar);
    if (Vfilevar.length > 0) {
      const formData = new FormData();
      [...Vfilevar].map(file => {
        formData.append('photo', file);
      });
      console.log(formData);
      //for single file working below code-----------------
      //   const formData = new FormData();
      //   formData.append('photo', file);
      //-----------------------------------------------------

      async function uploadphotofuncV(urlV, formData) {
        await Axios.post(urlV, formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            token: 'Bearer hjskdhskjdhsjkdhskjdhskjdhskdhskjdhsdjksjhdsjkdsdks',
          },
        })
          .then(response => {
            console.log(response);
            console.log('AC');
            //console.log(response.data.success);
            if (response.status === 200 && response.data.success === 'Success') {
              // console.log('insideeeee');
              // setImageURL1(response.data.url);
              notification.success({
                message: 'Image Added Successfully',
              });
              setTimeout(() => {
                notification.destroy();
              }, 3000);
              //console.log(response.data.url);
              const imageData = response.data.url;
              const images = imageData.toString();
              setVImageURL(images);
              //console.log(images);
              //empty the images uploaded--starts
              setDSelectedImages(null);
              //empty the images uploaded--starts
            } else {
              notification.error({
                message: 'Error in Image Upload!!',
              });
            }
          })
          .catch(err => {
            console.log('err', err);
          });
      }
      uploadphotofuncV(urlV, formData);
    } else {
      notification.error({
        message: 'Please Select Images First!!',
      });
    }
  };

  const [VSelectedImages, setVSelectedImages] = useState([]);

  const vehcleImageHandleChange = e => {
    setVFile(null);
    setVImageURL(null);
    setVDisableButton(true);
    //console.log('inside save');
    //  console.log(e.target.files);
    if (e.target.files.length > 0) {
      setVFile(e.target.files);
      //  setFileName1(e.target.files[0].name);
      setVDisableButton(false);
    } else {
      notification.error({
        message: 'Please Select Image!!',
      });
    }
    //console.log(e.target.files);
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map(file => URL.createObjectURL(file)); //to create a url path for images
      console.log(fileArray);

      setVSelectedImages(prev => prev.concat(fileArray));

      //setVSelectedImages(fileArray);
      Array.from(e.target.files).map(file => URL.revokeObjectURL(file));
    }
  };

  const VRenderPictures = source => {
    return source?.map((pictures, index) => {
      return (
        <>
          <Image src={pictures} key={pictures} />
          <Button
            type="danger"
            onClick={() => {
              setVSelectedImages(source.filter(e => e !== pictures));
              console.log(index);
              //remove array picture wd specific index ---STARTS
              //let dataimg = JSON.stringify(filevar)
              console.log(Vfilevar);
              const fileListArrV = Array.from(Vfilevar);
              console.log(fileListArrV);
              fileListArrV.splice(index, 1); // here u remove the file
              console.log(fileListArrV);
              setVFile(fileListArrV);
              //console.log(filevar);
              //remove array picture wd specific index ---ENDS
            }}
          >
            X
          </Button>
        </>
      );
    });
  };

  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++ENDS--Vehicle Image --MULTIPLE

  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++STARTS--Personal ID Image--MULTIPLE
  const [Pfilevar, setPFile] = useState(null);
  const [PImageURL, setPImageURL] = useState(null);
  const [PDisableButton, setPDisableButton] = useState(true);
  const urlP = domainpath + '/images/storegallery';

  const uploadFileP = async e => {
    console.log(Pfilevar);
    if (Pfilevar.length > 0) {
      const formData = new FormData();
      [...Pfilevar].map(file => {
        formData.append('photo', file);
      });
      //for single file working below code-----------------
      //   const formData = new FormData();
      //   formData.append('photo', file);
      //-----------------------------------------------------

      async function uploadphotofuncP(urlP, formData) {
        await Axios.post(urlP, formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            token: 'Bearer hjskdhskjdhsjkdhskjdhskjdhskdhskjdhsdjksjhdsjkdsdks',
          },
        })
          .then(response => {
            console.log(response);
            console.log('AC');
            //console.log(response.data.success);
            if (response.status === 200 && response.data.success === 'Success') {
              // console.log('insideeeee');
              // setImageURL1(response.data.url);
              notification.success({
                message: 'Image Added Successfully',
              });
              setTimeout(() => {
                notification.destroy();
              }, 3000);
              //console.log(response.data.url);
              const imageData = response.data.url;
              const images = imageData.toString();
              setPImageURL(images);
              //console.log(images);
              //empty the images uploaded--starts
              setDSelectedImages(null);
              //empty the images uploaded--starts
            } else {
              notification.error({
                message: 'Error in Image Upload!!',
              });
            }
          })
          .catch(err => {
            console.log('err', err);
          });
      }
      uploadphotofuncP(urlP, formData);
    } else {
      notification.error({
        message: 'Please Select Images First!!',
      });
    }
  };

  const [PSelectedImages, setPSelectedImages] = useState([]);

  const personalIdImageHandleChange = e => {
    setPFile(null);
    setPImageURL(null);
    setPDisableButton(true);
    //console.log('inside save');
    //  console.log(e.target.files);
    if (e.target.files.length > 0) {
      setPFile(e.target.files);
      //  setFileName1(e.target.files[0].name);
      setPDisableButton(false);
    } else {
      notification.error({
        message: 'Please Select Image!!',
      });
    }
    //console.log(e.target.files);
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map(file => URL.createObjectURL(file)); //to create a url path for images
      console.log(fileArray);

      setPSelectedImages(prev => prev.concat(fileArray));

      // setPSelectedImages(fileArray);
      Array.from(e.target.files).map(file => URL.revokeObjectURL(file));
    }
  };

  const PRenderPictures = source => {
    return source?.map((pictures, index) => {
      return (
        <>
          <Image src={pictures} key={pictures} />
          <Button
            type="danger"
            onClick={() => {
              setPSelectedImages(source.filter(e => e !== pictures));
              console.log(index);
              //remove array picture wd specific index ---STARTS
              //let dataimg = JSON.stringify(filevar)
              console.log(Pfilevar);
              const fileListArrP = Array.from(Pfilevar);
              console.log(fileListArrP);
              fileListArrP.splice(index, 1); // here u remove the file
              console.log(fileListArrP);
              setPFile(fileListArrP);
              //console.log(filevar);
              //remove array picture wd specific index ---ENDS
            }}
          >
            X
          </Button>
        </>
      );
    });
  };

  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++ENDS--Personal ID Image --MULTIPLE

  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++STARTS--DL ID Image--MULTIPLE
  const [Dfilevar, setDFile] = useState(null);
  const [DImageURL, setDImageURL] = useState(null);
  const [DDisableButton, setDDisableButton] = useState(true);
  const urlD = domainpath + '/images/storegallery';

  const uploadFileD = async e => {
    console.log(Dfilevar);
    if (Dfilevar.length > 0) {
      const formData = new FormData();
      [...Dfilevar].map(file => {
        formData.append('photo', file);
      });

      async function uploadphotofuncP(urlD, formData) {
        await Axios.post(urlD, formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            token: 'Bearer hjskdhskjdhsjkdhskjdhskjdhskdhskjdhsdjksjhdsjkdsdks',
          },
        })
          .then(response => {
            console.log(response);
            console.log('AC');
            //console.log(response.data.success);
            if (response.status === 200 && response.data.success === 'Success') {
              // console.log('insideeeee');
              // setImageURL1(response.data.url);
              notification.success({
                message: 'Image Added Successfully',
              });
              setTimeout(() => {
                notification.destroy();
              }, 3000);
              //console.log(response.data.url);
              const imageData = response.data.url;
              const images = imageData.toString();
              setDImageURL(images);
              //console.log(images);
              //empty the images uploaded--starts
              setPSelectedImages(null);
              //empty the images uploaded--starts
            } else {
              notification.error({
                message: 'Error in Image Upload!!',
              });
            }
          })
          .catch(err => {
            console.log('err', err);
          });
      }
      uploadphotofuncP(urlD, formData);
    } else {
      notification.error({
        message: 'Please Select Images First!!',
      });
    }
  };

  const [DSelectedImages, setDSelectedImages] = useState([]);

  const DlImageHandleChange = e => {
    setDFile(null);
    setDImageURL(null);
    setDDisableButton(true);
    //console.log('inside save');
    //  console.log(e.target.files);
    if (e.target.files.length > 0) {
      setDFile(e.target.files);
      //  setFileName1(e.target.files[0].name);
      setDDisableButton(false);
    } else {
      notification.error({
        message: 'Please Select Image!!',
      });
    }
    //console.log(e.target.files);
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map(file => URL.createObjectURL(file)); //to create a url path for images
      console.log(fileArray);

      setDSelectedImages(prev => prev.concat(fileArray));

      // setDSelectedImages(fileArray);
      Array.from(e.target.files).map(file => URL.revokeObjectURL(file));
    }
  };

  const DRenderPictures = source => {
    return source?.map((pictures, index) => {
      return (
        <>
          <Image src={pictures} key={pictures} />
          <Button
            type="danger"
            onClick={() => {
              setDSelectedImages(source.filter(e => e !== pictures));
              console.log(index);
              //remove array picture wd specific index ---STARTS
              //let dataimg = JSON.stringify(filevar)
              console.log(Dfilevar);
              const fileListArrD = Array.from(Dfilevar);
              console.log(fileListArrD);
              fileListArrD.splice(index, 1); // here u remove the file
              console.log(fileListArrD);
              setDFile(fileListArrD);
              //console.log(filevar);
              //remove array picture wd specific index ---ENDS
            }}
          >
            X
          </Button>
        </>
      );
    });
  };

  //IMAGE FILE HANDLING+++++++++++++++++++++++++++++++++++++++++++++++++++++ENDS--DL ID Image --MULTIPLE

  //const [storeStatus, setStoreStatus] = useState();

  const handleSubmit = fieldsValue => {
    if (simpleStoreGalleryimagesURL !== null) {
      const imagesString = JSON.stringify(simpleStoreGalleryimagesURL);

      if (typeof fieldsValue.images === 'undefined' && imageURL !== null) {
        const userid = decodetheid(userID);
        console.log(userid);
        const string_operationDays = JSON.stringify(OperationDays);
        console.log(userid);
        const o_time = fieldsValue.open_time;
        const c_time = fieldsValue.close_time;
        const open_time = moment(o_time).format('HH:mm');
        const close_time = moment(c_time).format('HH:mm');
        var payload = {
          images: imageURL,
          created_by: userid,
          store_type: fieldsValue.store_type,
          name: fieldsValue.name,
          email: fieldsValue.email,
          phone: fieldsValue.phone,
          address_line_1: fieldsValue.address_line_1,
          address_line_2: fieldsValue.address_line_2,
          country: fieldsValue.country,
          state: fieldsValue.state,
          city: fieldsValue.city,
          latitude: fieldsValue.latitude,
          longitude: fieldsValue.longitude,
          postal_code: fieldsValue.postal_code,
          store_taxes: fieldsValue.store_taxes,

          meta: {
            owner_name: fieldsValue.owner_name,
            validID_type: fieldsValue.validID_type,
            store_licence_number: fieldsValue.store_licence_number,
            store_licence_imageURL: store_licence_imageURL,
            store_ValidID_imageURL: store_ValidID_imageURL,
            mode_of_payment: fieldsValue.mode_of_payment,
            other_info: fieldsValue.other_info,
            minimum_order: fieldsValue.minimum_order,
            operation_days: string_operationDays,
            open_time: open_time,
            close_time: close_time,
            is_dining_active: checkbox,
            is_pickup_active: checdining,
            gallery_images: imagesString,
            image_meta: JSON.stringify(imageUrlMeta),
            operation_days: JSON.stringify(OperationDays),
          },
        };
      }
    }

    // const open_time = moment(fieldsValue?.open_time).format('HH:mm');
    //const close_time = moment(fieldsValue?.close_time).format('HH:mm');
    //const operation_days_data = fieldsValue.operation_days;

    //const mode_of_payment_data = fieldsValue.mode_of_payment;
    // payload.open_time = open_time;
    // payload.close_time = close_time;
    // payload.is_dining_active = checkbox;
    // payload.is_pickup_active = checdining;
    //var operation_days = JSON.stringify(operation_days_data);//json
    //let operation_days = operation_days_data.toString(); //string
    // console.log(operation_days);
    //let mode_of_payment = mode_of_payment_data.toString(); //string
    //   console.log(mode_of_payment);
    //array into string--------------------------------------------ends
    // fieldsValue.operation_days = OperationDays;
    // payload.mode_of_payment = mode_of_payment;

    //AS image field is empty and if imageurl exists so insert imageurl into field value "image"

    // payload.store_licence_imageURL = store_licence_imageURL;
    // payload.store_ValidID_imageURL = store_ValidID_imageURL;
    //open_time: '',
    if (ShowDeliverySection == 3) {
      payload.meta.licence_number = DeliveryPartnerDetails.licence_number;
      payload.meta.vechicle_type = DeliveryPartnerDetails.vechicle_type;
      payload.meta.vechicle_colour = DeliveryPartnerDetails.vechicle_colour;
      payload.meta.transport_mode = DeliveryPartnerDetails.transport_mode;
      payload.meta.fuel_type = DeliveryPartnerDetails.fuel_type;
      payload.meta.vechicle_number = DeliveryPartnerDetails.vechicle_number;
      payload.meta.deliveryVehicleimagefront = deliveryVehicleimageFrontURL;
      payload.meta.deliveryVehicleimageback = deliveryVehicleimagebackURL;
      payload.meta.licence_imagefront = deliveryimageFrontURL;
      payload.meta.licence_imageback = deliveryimagebackURL;
    }
    console.log(fieldsValue);

    console.log(payload);
    async function createStore(data) {
      if (userID) {
        const decCustID = decodetheid(userID);
        //
        payload['created_by'] = decCustID;

        async function sendData(Payload_data) {
          console.log(JSON.stringify(Payload_data));
          await Axios.post(domainpath + `/store/add`, Payload_data, {
            headers,
          }).then(response => {
            console.log(response);
            if (response.status === 201) {
              const newstore_ID = response?.data?.store_id;
              console.log(newstore_ID);
              const id = newstore_ID;

              setcreatedStoreID(id);
              if (response.data.status == 'success') {
                notification.success({
                  message: 'Store Request Created Successfully',
                });
                setReview(true);

                // //now send data to user_role api---------------ENDS
              }
              setTimeout(() => {
                notification.destroy();
                // history.push('../stores/stores');
              }, 2500);
            } else {
              notification.error({ message: 'Server error' });
              setTimeout(() => {
                notification.destroy();
              }, 2000);
            }
          });
        }
        sendData(data);
        //Process to send data--ENDS
      } else if (response.status === 400) {
        setError('Server Error');
      }
      //  });
      //get id of logged in user----------------------------------------------ENDS
    }
    createStore(payload);
    //Now create Store===============================================================ENDS
  };

  return (
    <>
      {review == true ? (
        <Main>
          <div className="emptycartt">
            <img src={ReviewIcon} className="img-fluid" alt="" />
            <p>Your store information is under review</p>
            <Button type="primary" onClick={() => history.push(`/dashboard/stores/edit-new-store/${createdStoreID}`)}>
              View your application
            </Button>
          </div>
        </Main>
      ) : (
        <>
          <div className="application">
            <Helmet>
              <script
                src="https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false&key=AIzaSyCfRNteZC3NpiinARdRkOfHWYyP0ezGcio&callback=initAutocomplete&libraries=places&v=weekly"
                defer
              ></script>
            </Helmet>
          </div>
          <div>
            <input
              id="pac-input"
              className="controls"
              type="text"
              placeholder="Search Box"
              //value={search_address}
              onChange={inputChange}
            />
            <div id="map"></div>
          </div>

          <PageHeader ghost title="Add Store" />
          {getModule.length != 1 ? (
            <Main>
              <Row gutter={15}>
                <Col xs={24}>
                  <AddUser>
                    <Cards
                      title={
                        <div className="card-nav">
                          <ul>
                            <li>
                              <NavLink to={`#`} onClick={e => e.preventDefault()}>
                                <FeatherIcon icon="user" size={14} />
                                User Info
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      }
                    >
                      <Form
                        name="sDash_validation-form"
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                        onChange={handleChange}
                      >
                        <h3>
                          <span className="TitleUserAdd">Personal Information</span>
                        </h3>

                        <Row gutter={30}>
                          <Col md={12} xs={24}>
                            <Row gutter={30}>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  name="store_type"
                                  initialValue=""
                                  label="Store Type"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select role !',
                                    },
                                  ]}
                                >
                                  <Select
                                    style={{ width: '100%' }}
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    arrayDataStoreType={arrayDataStoreType}
                                    onChange={storetypeselectedToGetstore}
                                    onSelect={GetPosition}
                                  >
                                    {arrayDataStoreType != null
                                      ? arrayDataStoreType.map((item, index) => (
                                          <Option value={item.id}>{item.name} </Option>
                                        ))
                                      : ''}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  name="name"
                                  label="Store Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Store Name is required !',
                                    },
                                  ]}
                                >
                                  <Input
                                    onChange={selectedValue => {
                                      setformData({ ...formData, name: selectedValue.target.value });
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={24} xs={24}>
                                <Form.Item className="fullsizefield" name="other_info" label="Store Description">
                                  <TextArea
                                    rows={8}
                                    showCount
                                    maxLength={100}
                                    style={{ height: 120 }}
                                    onChange={changeArea}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={12} xs={24}>
                                <Form.Item
                                  name="owner_name"
                                  initialValue=""
                                  label="Owner Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Select store type User!',
                                    },
                                  ]}
                                >
                                  <Select style={{ width: '100%' }} onSelect={storetypeselectedToGetUserDetail}>
                                    {arrayStoreUser != null
                                      ? arrayStoreUser.map(item => {
                                          return <Option value={item.id}>{item.name} </Option>;
                                        })
                                      : ''}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  name="email"
                                  label="Email"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Email Address !',
                                    },
                                  ]}
                                >
                                  <Select style={{ width: '100%' }} arrayStoreUserDetail={arrayStoreUserDetail}>
                                    {arrayStoreUserDetail != null
                                      ? arrayStoreUserDetail.map(item => {
                                          return <Option value={item.email}>{item.email}</Option>;
                                        })
                                      : ''}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  name="phone"
                                  label="Phone"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter Phone Number !',
                                    },
                                  ]}
                                >
                                  <Select>
                                    {arrayStoreUserDetail != null
                                      ? arrayStoreUserDetail.map(item => {
                                          return <Option value={item.phone}>{item.phone}</Option>;
                                        })
                                      : ''}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item name="is_pickup_active" label="Pickup Options">
                                  <Checkbox
                                    //disabled
                                    checked={checkbox}
                                    onChange={onChangeChecbox}
                                  >
                                    Dine-in
                                  </Checkbox>
                                  <Checkbox
                                    // disabled
                                    checked={checdining}
                                    onChange={onChangeChecbox2}
                                  >
                                    Take away
                                  </Checkbox>

                                  {/* </Radio.Group> */}
                                </Form.Item>
                              </Col>
                              <Col md={24}>
                                <h3>
                                  <span className="TitleUserAdd">Address</span>
                                </h3>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  //value={''}
                                  name="address_line_1"
                                  label="Address Line 1"
                                >
                                  <Input
                                    type="text"
                                    placeholder="Street and locality"
                                    onChange={selectedValue => {
                                      setformData({ ...formData, address_line_1: selectedValue.target.value });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item name="address_line_2" label="Address Line 2">
                                  <Input
                                    placeholder="Street and locality"
                                    //defaultValue={administrative_area_level_21}
                                    onChange={selectedValue => {
                                      setformData({ ...formData, address_line_2: selectedValue.target.value });
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={12} xs={24}>
                                <Form.Item name="country" label="Country">
                                  <Select
                                    style={{ width: '100%' }}
                                    arraydatacountry={arraydatacountry}
                                    onChange={storetypeselectedToGetstate}
                                  >
                                    {arraydatacountry != null
                                      ? arraydatacountry.map(item => {
                                          return <Option value={item.id}>{item.name} </Option>;
                                        })
                                      : ''}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item name="state" label="State">
                                  <Select
                                    style={{ width: '100%' }}
                                    arraydatastate={arraydatastate}
                                    onChange={selectedValue => {
                                      citynamebystate(selectedValue);
                                    }}
                                  >
                                    {arraydatastate != null
                                      ? arraydatastate.map(item => {
                                          return <Option value={item.id}>{item.name} </Option>;
                                        })
                                      : ''}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item name="city" label="City">
                                  <Select
                                    defaultValue={address?.city}
                                    onChange={selectedValue => setaddress({ ...address, city: selectedValue })}
                                  >
                                    {cities != null
                                      ? cities.map(item => {
                                          return <Option value={item.id}>{item.name} </Option>;
                                        })
                                      : ''}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col md={12} xs={24}>
                                <Form.Item name="postal_code" label="Zip/Postal/Code">
                                  <Input
                                    type="text"
                                    placeholder="160071"
                                    style={{ width: '100%' }}
                                    //defaultValue={postal_code1}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item name="latitude" label="Latitude">
                                  <Input
                                    placeholder="Enter Your Latitude"
                                    // onChange={selectedValue => {
                                    //   setStoreData({ ...StoreData, lat: selectedValue.target.value });
                                    // }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item name="longitude" label="Longitude">
                                  <Input
                                    placeholder="Enter Your Longitude"
                                    // onChange={selectedValue => {
                                    //   setStoreData({ ...StoreData, lng: selectedValue.target.value });
                                    // }}
                                  />
                                </Form.Item>
                              </Col>
                              {/* <Col md={12} xs={24}>
                                <Form.Item name="general_cost" label="General Cost">
                                  <Input placeholder="general cost of food item" type="number" />
                                </Form.Item>
                              </Col> */}
                              <Col md={12} xs={24}>
                                <Form.Item className="fullsizefield" name="mode_of_payment" label="Mode of payment">
                                  <Select
                                    mode="multiple"
                                    options={payment}
                                    style={{ width: '100%' }}
                                    //defaultValue={1}
                                    onChange={handlePayment}
                                  ></Select>
                                </Form.Item>
                              </Col>
                              {UserRole == 'SUPER_ADMIN'
                                ? [
                                    <Col md={12} xs={24}>
                                      <Form.Item name="position" label="Store Position">
                                        <Select style={{ width: '100%' }} arraydatapostions={arraydatapostions}>
                                          {arraydatapostions != null
                                            ? arraydatapostions.map(item => {
                                                return <Option value={item}>{item} </Option>;
                                              })
                                            : ''}
                                        </Select>
                                      </Form.Item>
                                    </Col>,
                                  ]
                                : ''}
                              <Col lg={12} md={6} xs={24}>
                                <Form.Item name="minimum_order" className="fullsizefield" label="Minimum Order Amount">
                                  <InputNumber min={0} type="number" style={{ width: '100%' }} />
                                </Form.Item>
                              </Col>

                              <Col md={24} xs={24}>
                                <Form.Item name="store_taxes" className="fullsizefield" label="Store Taxes">
                                  <Select style={{ width: '100%' }} arraydataTaxByCountry={arraydataTaxByCountry}>
                                    {arraydataTaxByCountry != null
                                      ? arraydataTaxByCountry.map(item => {
                                          return <Option value={item.id}>{item.name}</Option>;
                                        })
                                      : ''}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col md={24}>
                                <h3>
                                  <span className="TitleUserAdd">Store Licence number</span>
                                </h3>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  name="store_licence_number"
                                  label="Store Licence Number"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: 'Please Enter Your Licence Number!',
                                  //   },
                                  // ]}
                                >
                                  <Input
                                    placeholder="Enter Your Licence Number"
                                    type="number"
                                    // onChange={selectedValue => {
                                    //   setStoreData({
                                    //     ...StoreData,
                                    //     store_licence_number: selectedValue.target.value,
                                    //   });
                                    // }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} xs={24}>
                                <Col md={24} xs={24} className="imageoverlay1">
                                  <Form.Item
                                    name="front_image"
                                    //label=" Front Image"  store_ValidID_image
                                  >
                                    <label for="file-input21" style={{ position: 'relative' }}>
                                      <div className="addpageupload categoryEditResult text">
                                        {store_licence_image.length > 0 ? (
                                          <img className="imgse renderCategoryEdit" src={store_licence_image} />
                                        ) : (
                                          <img className="imgse staticImagewid" src={StaticImage} />
                                        )}
                                      </div>
                                    </label>
                                    <Input
                                      style={{ opacity: '0' }}
                                      name="front_image"
                                      type="file"
                                      onChange={e => imageHandleChangeStoreLicence(e)}
                                      id="file-input21"
                                    />
                                  </Form.Item>
                                </Col>
                              </Col>
                              <Col md={24}>
                                <h3>
                                  <span className="TitleUserAdd">Valid ID</span>
                                </h3>
                              </Col>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  name="validID_type"
                                  className="fullsizefield"
                                  label="Document Type"
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: 'Please Select Document Type',
                                  //   },
                                  // ]}
                                >
                                  <Select
                                    style={{ width: '100%' }}
                                    // onChange={selectedValue => {
                                    //   setStoreData({
                                    //     ...StoreData,
                                    //     validID_type: selectedValue,
                                    //   });
                                    // }}
                                  >
                                    <Option value="Licence">Licence</Option>
                                    <Option value="Store-ID">Store-ID</Option>
                                    <Option value="Store-Slip">Store-Slip</Option>
                                    <Option value="PassPort">PassPort</Option>
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col md={8} xs={24}>
                                <Col md={24} xs={24} className="imageoverlay1">
                                  <Form.Item
                                    name="validID_image"
                                    //label=" ID Image"
                                  >
                                    <label for="file-input22" style={{ position: 'relative' }}>
                                      <div className="addpageupload categoryEditResult text">
                                        {store_ValidID_image.length > 0 ? (
                                          <img className="imgse renderCategoryEdit" src={store_ValidID_image} />
                                        ) : (
                                          <img className="imgse staticImagewid" src={StaticImage} />
                                        )}
                                      </div>
                                    </label>
                                    <Input
                                      style={{ opacity: '0' }}
                                      name="validID_image"
                                      type="file"
                                      onChange={e => imageHandleChangeStoreLicence(e)}
                                      id="file-input22"
                                    />
                                  </Form.Item>
                                </Col>
                              </Col>
                              {/*********************************************Delivery Section STARTS**********************************/}
                              {ShowDeliverySection == 3 ? (
                                <>
                                  <Col md={24}>
                                    <h3>
                                      <span className="TitleUserAdd">Delivery Partner</span>
                                    </h3>
                                  </Col>
                                  <Col md={12} xs={24}>
                                    <Form.Item
                                      name="licence_number"
                                      label="Driving Licence Number"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please Enter Your Licence Number!',
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Enter Your Licence Number"
                                        type="number"
                                        onChange={selectedValue => {
                                          setDeliveryPartnerDetails({
                                            ...DeliveryPartnerDetails,
                                            licence_number: selectedValue.target.value,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col md={6} xs={24}>
                                    <Col md={24} xs={24} className="imageoverlay1">
                                      <Form.Item name="front_image" label=" Front Image">
                                        <label for="file-input1" style={{ position: 'relative' }}>
                                          <div className="addpageupload categoryEditResult text">
                                            {deliveryimageFront.length > 0 ? (
                                              <img className="imgse renderCategoryEdit" src={deliveryimageFront} />
                                            ) : (
                                              <img className="imgse staticImagewid" src={StaticImage} />
                                            )}
                                          </div>
                                          {/* <div class="overlaytexts">
                                            <div class="text">Change Image</div>
                                          </div> */}
                                          {/* {renderPictures(Images)} */}
                                        </label>
                                        <Input
                                          style={{ opacity: '0' }}
                                          //required
                                          name="front_image"
                                          type="file"
                                          onChange={e => imageHandleChangeDelivery(e)}
                                          id="file-input1"
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Col>
                                  <Col md={6} xs={24}>
                                    <Col md={24} xs={24} className="imageoverlay2">
                                      <Form.Item name="back_image" label=" Back Image">
                                        <label for="file-input3" style={{ position: 'relative' }}>
                                          <div className="addpageupload categoryEditResult text">
                                            {deliveryimageback.length > 0 ? (
                                              <img className="imgse renderCategoryEdit" src={deliveryimageback} />
                                            ) : (
                                              <img className="imgse staticImagewid" src={StaticImage} />
                                            )}
                                          </div>
                                          {/* <div class="overlaytexts">
                                            <div class="text">Change Image</div>
                                          </div> */}
                                          {/* {renderPictures(Images)} */}
                                        </label>
                                        <Input
                                          style={{ opacity: '0' }}
                                          //required
                                          name="back_image"
                                          type="file"
                                          onChange={e => imageHandleChangeDelivery(e)}
                                          id="file-input3"
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Col>
                                  <Col md={24}>
                                    <h3>
                                      <span className="TitleUserAdd">Vehicle Details</span>
                                    </h3>
                                  </Col>
                                  <Col md={12} xs={24}>
                                    <Form.Item
                                      name="vechicle_type"
                                      className="fullsizefield"
                                      label="Vechicle Type"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please Select Vechicle Type',
                                        },
                                      ]}
                                    >
                                      <Select
                                        style={{ width: '100%' }}
                                        onChange={selectedValue => {
                                          setDeliveryPartnerDetails({
                                            ...DeliveryPartnerDetails,
                                            vechicle_type: selectedValue,
                                          });
                                        }}
                                      >
                                        <Option value="Bicycle">Bicycle</Option>
                                        <Option value="Motor-Bike">Motor-Bike</Option>
                                        <Option value="Car">Car</Option>
                                        <Option value="Heavy-Vehicle">Heavy-Vehicle</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col md={6} xs={24}>
                                    <Col md={24} xs={24} className="imageoverlay2">
                                      <Form.Item name="vechicle_front_image" label=" Vehicle Front">
                                        <label for="file-input2" style={{ position: 'relative' }}>
                                          <div className="addpageupload categoryEditResult text">
                                            {deliveryVehicleimageFront.length > 0 ? (
                                              <img
                                                className="imgse renderCategoryEdit"
                                                src={deliveryVehicleimageFront}
                                              />
                                            ) : (
                                              <img className="imgse staticImagewid" src={StaticImage} />
                                            )}
                                          </div>
                                          {/* <div class="overlaytexts">
                                            <div class="text">Change Image</div>
                                          </div> */}
                                          {/* {renderPictures(Images)} */}
                                        </label>
                                        <Input
                                          style={{ opacity: '0' }}
                                          //required
                                          name="vechicle_front_image"
                                          type="file"
                                          onChange={e => imageHandleChangeDeliveryVehicle(e)}
                                          id="file-input2"
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Col>
                                  <Col md={6} xs={24}>
                                    <Col md={24} xs={24} className="imageoverlay3">
                                      <Form.Item name="vechicle_back_image" label=" Vehicle Back">
                                        <label for="file-input0" style={{ position: 'relative' }}>
                                          <div className="addpageupload categoryEditResult text">
                                            {deliveryVehicleimageback.length > 0 ? (
                                              <img
                                                className="imgse renderCategoryEdit"
                                                src={deliveryVehicleimageback}
                                              />
                                            ) : (
                                              <img className="imgse staticImagewid" src={StaticImage} />
                                            )}
                                          </div>
                                          {/* <div class="overlaytexts">
                                            <div class="text">Change Image</div>
                                          </div> */}
                                          {/* {renderPictures(Images)} */}
                                        </label>
                                        <Input
                                          style={{ opacity: '0' }}
                                          //required
                                          name="vechicle_back_image"
                                          type="file"
                                          onChange={e => imageHandleChangeDeliveryVehicle(e)}
                                          id="file-input0"
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Col>
                                  <Col md={8} xs={24}>
                                    <Form.Item
                                      name="vechicle_colour"
                                      label="Colour"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please Select Colour',
                                        },
                                      ]}
                                    >
                                      <Select
                                        style={{ width: '100%' }}
                                        onChange={selectedValue => {
                                          setDeliveryPartnerDetails({
                                            ...DeliveryPartnerDetails,
                                            vechicle_colour: selectedValue,
                                          });
                                        }}
                                      >
                                        <Option value="Red">Red</Option>
                                        <Option value="Yellow">Yellow</Option>
                                        <Option value="Black">Black</Option>
                                        <Option value="White">White</Option>
                                        <Option value="Blue">Blue</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} xs={24}>
                                    <Form.Item
                                      name="transport_mode"
                                      className="fullsizefield"
                                      label="Transport Mode"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please Select Transport Mode',
                                        },
                                      ]}
                                    >
                                      <Select
                                        style={{ width: '100%' }}
                                        onChange={selectedValue => {
                                          setDeliveryPartnerDetails({
                                            ...DeliveryPartnerDetails,
                                            transport_mode: selectedValue,
                                          });
                                        }}
                                      >
                                        <Option value="Food">Food</Option>
                                        <Option value="Transport">Transport</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} xs={24}>
                                    <Form.Item
                                      name="fuel_type"
                                      className="fullsizefield"
                                      label="Fuel Type"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please Select Fuel Type',
                                        },
                                      ]}
                                    >
                                      <Select
                                        style={{ width: '100%' }}
                                        onChange={selectedValue => {
                                          setDeliveryPartnerDetails({
                                            ...DeliveryPartnerDetails,
                                            fuel_type: selectedValue,
                                          });
                                        }}
                                      >
                                        <Option value="Petrol">Petrol</Option>
                                        <Option value="Diesel">Diesel</Option>
                                        <Option value="Bio-Diesel">Bio-Diesel</Option>
                                        <Option value="CNG">CNG</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} xs={24}>
                                    <Form.Item
                                      name="vechicle_number"
                                      className="fullsizefield"
                                      label="Vehicle Number"
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please Select Vehicle Number',
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Enter Your Vehicle Number"
                                        type="mix"
                                        onChange={selectedValue => {
                                          setDeliveryPartnerDetails({
                                            ...DeliveryPartnerDetails,
                                            vechicle_number: selectedValue.target.value,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              ) : (
                                ''
                              )}
                              {/********************************************Delivery Section ENDS**************************************/}
                            </Row>
                          </Col>
                          <Col md={12} xs={24}>
                            <Row gutter={30}>
                              {/* <Col md={8} xs={24} style={{ textAlign: 'center' }}>
                                <Form.Item name="status">
                                  {'Status : '}
                                  <Switch onChange={onChange} />
                                </Form.Item>
                              </Col> */}
                              <Col md={24} xs={24} className="imageoverlay1">
                                <Form.Item
                                  name="images"
                                  label=""
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please select Store Featured Image!',
                                    },
                                  ]}
                                >
                                  <label for="file-input-images2" style={{ position: 'relative' }}>
                                    <div className="addpageupload categoryEditResult text">
                                      {images.length > 0 ? (
                                        <img className="imgse renderCategoryEdit" src={images} />
                                      ) : (
                                        <img className="imgse staticImagewid" src={StaticImage} />
                                      )}
                                    </div>
                                    {/* <div class="overlaytext">
                                      <div class="text">Change Image</div>
                                    </div> */}
                                    {/* {renderPictures(Images)} */}
                                  </label>
                                  <Input
                                    style={{ opacity: '0' }}
                                    //required
                                    name="images"
                                    type="file"
                                    onChange={e => imageHandleChange(e)}
                                    id="file-input-images2"
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={24} xs={24}>
                                <Form.Item
                                  className="fullsizefield"
                                  name="gallery_images"
                                  label="Gallery Images"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please select Store Gallery Images!',
                                    },
                                  ]}
                                >
                                  <Input
                                    //required
                                    type="file"
                                    name="gallery_images"
                                    multiple
                                    onChange={e => imageHandleChange(e)}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={24} xs={24}>
                                <div id="renderimgsize" className="result fullsizefield" style={{ width: '20vh' }}>
                                  {renderPictures(simpleStoreGalleryImages)}
                                </div>
                              </Col>

                              <Col md={12} xs={24}>
                                <Form.Item name="open_time" label="Open Time">
                                  <TimePicker
                                    defaultValue={moment('01:00', format)}
                                    format={format}
                                    onChange={onChangeOpenTime}
                                    style={{ width: '20%' }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={12} xs={24}>
                                <Form.Item name="close_time" label="Close Time">
                                  <TimePicker
                                    defaultValue={moment('20:55', format)}
                                    format={format}
                                    onChange={onChangeCloseTime}
                                    style={{ width: '20%' }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={24} xs={24} className="StorelistOpening">
                                <Form.Item
                                  className="fullsizefield"
                                  name="operation_days"
                                  label="Opening days"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Opening days',
                                    },
                                  ]}
                                >
                                  <Select
                                    mode="multiple"
                                    options={days}
                                    onChange={handleOpeningDays}
                                    //defaultValue={1}
                                    style={{ width: '100%' }}
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Col md={24} xs={24} className="DaysFieldsSet">
                                {splitDays?.map(item1 => (
                                  <Form.Item className="fullsizefield" label={item1}>
                                    <TimePicker.RangePicker
                                      label={item1}
                                      Value={[moment('01:00:02'), moment(' 02:00:02')]}
                                      showTime={{ format: 'hh:mm:ss' }}
                                      format="hh:mm:ss"
                                      onChange={(value, e) => handledOpeningDaysAndTime(value, e, { item1 })}
                                    />
                                  </Form.Item>
                                ))}
                              </Col>
                              <Col md={24} xs={24}></Col>
                            </Row>
                          </Col>
                        </Row>

                        {/* //row------------------------------------------------------------------------------------------- */}
                        <Row style={{ float: 'right' }}>
                          <Form.Item>
                            <div className="add-user-bottom text-right">
                              <Button htmlType="submit" type="success" size="default">
                                Submit
                              </Button>
                            </div>
                          </Form.Item>
                        </Row>
                      </Form>
                    </Cards>
                  </AddUser>
                </Col>
              </Row>
            </Main>
          ) : (
            <div className="authorized_msg">
              <img src={authorizes} width="40%" />
            </div>
          )}
        </>
      )}
    </>
  );
};

AddNewStore.propTypes = {
  match: propTypes.object,
};

export default AddNewStore;
