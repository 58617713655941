//import Cookies from 'js-cookie';
import actions from './actions';
import Axios from 'axios';
import { headers } from '../../helpers/variables';
//import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
//const cookies = new Cookies();
import Cookies from 'js-cookie';
import { notification } from 'antd';
import { DeviceUUID } from 'device-uuid';
const { encrypt, decrypt, decryptGlobal } = require('../../helpers/encryption-decryption');

var du = new DeviceUUID().parse();
var dua = [
  du.language,
  du.platform,
  du.os,
  du.cpuCores,
  du.isAuthoritative,
  du.silkAccelerated,
  du.isKindleFire,
  du.isDesktop,
  du.isMobile,
  du.isTablet,
  du.isWindows,
  du.isLinux,
  du.isLinux64,
  du.isMac,
  du.isiPad,
  du.isiPhone,
  du.isiPod,
  du.isSmartTV,
  du.pixelDepth,
  du.isTouchScreen,
];
var uuid = du.hashMD5(dua.join(':'));
var userDetail;
var accesstoken;
//const jj = decrypt(kk);
//console.log(jj);
const domainpath = process.env.REACT_APP_DOMAIN_ENDPOINT;

const {
  // alreadyloggedinBegin,
  // alreadyloggedinSuccess,
  // alreadyloggedinErr,
  loginBegin,
  loginSuccess,
  loginUnSuccessfull,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

var currentdS_ID;
var stores;

const login = postData => {
  const session_id = Cookies.get('sessionID');
  Cookies.remove('UserDetail');
  Cookies.remove('access_token');
  sessionStorage.clear();
  return async dispatch => {
    try {
      dispatch(loginBegin());

      headers.session_id = session_id;
      await Axios.post(domainpath + '/user/login', postData, {
        headers,
      }).then(async response => {
        userDetail = response?.data?.sessdata;
        currentdS_ID = response?.data?.sessdata?.currentstore?.store_id;
        stores = response?.data?.sessdata?.store;
        accesstoken = response?.data?.sessdata?.access_token;
        const enc_userdetial = await encrypt(userDetail);

        sessionStorage.setItem('UserDetail', enc_userdetial);
        sessionStorage.setItem('access_token', accesstoken);

        if (response.status === 200) {
          Cookies.set('UserDetail', enc_userdetial);
          Cookies.set('access_token', accesstoken);

          const store_got = response?.data.sessdata.store;
          if (store_got != null) {
            setTimeout(() => {
              //return history.push('/');
              window.location.href = '/dashboard';
              return dispatch(loginSuccess(true, response));
            }, 100);
          } else if (store_got && store_got.length > 0) {
            console.log('Store FOund');
            const len = store_got?.length;
            console.log(len);
            if (len == 1) {
              console.log('Store Found length');

              if (response.data.sessdata.store[0].permissions == 'Error') {
                console.log('Contact Administrator -- Permissions Error');
                notification.error({
                  message: 'Contact Administrator -- Permissions Error',
                });
                console.log('Doing Logout');
                //headers from variables was containing empty token so doing this------STARTS
                // const access_token = Cookies.get('access_token');
                // //console.log(access_token);
                // const headers = {
                //   'Content-Type': 'application/json; charset=UTF-8',
                //   token: 'Bearer ' + access_token,
                //   //token: [access_token],
                //   device_id: '',
                //   api_version: '',
                //   browser: '',
                //   device_type: '',
                // };

                //headers from variables was containing empty token so doing this------ENDS
                // Axios.get(domainpath + '/user/logout', { headers }).then(response => {
                //   console.log(response);
                //   if (response.status === 200) {
                //     // Cookies.remove('logedIn');
                //     console.log('action creator-LOGOUT session destroyed');
                //     Cookies.remove('UserDetail');
                //     Cookies.remove('access_token');
                //     sessionStorage.clear();
                //     history.push('/');
                //   }
                //   //cookies.remove('UserDetail');
                //   //cookies.remove('access_token');

                //   return dispatch(loginUnSuccessfull(false));
                // });
                logOut();
                return dispatch(loginUnSuccessfull(false));
              } else {
                return dispatch(loginSuccess(true, response));
              }
              // history.push(`../users/edit-users-role/${postID}`);
            } else {
              stores?.map(item => {
                //   console.log(item);
                console.log(headers);
                if (item.store_id == currentdS_ID) {
                  console.log(item);

                  if (item.permissions == 'Error') {
                    //headers from variables was containing empty token so doing this------STARTS
                    // const access_token = Cookies.get('access_token');
                    //console.log(access_token);
                    // const headers = {
                    //   'Content-Type': 'application/json; charset=UTF-8',
                    //   //token: 'Bearer ' + access_token,
                    //   token: [access_token],
                    //   device_id: '',
                    //   api_version: '',
                    //   browser: '',
                    //   device_type: '',
                    // };
                    // console.log(headers);
                    //headers from variables was containing empty token so doing this------ENDS
                    // Axios.get(domainpath + '/user/logout', { headers }).then(response => {
                    //   console.log(response);
                    //   if (response.status === 200) {
                    //     // Cookies.remove('logedIn');
                    //     console.log('action creator-LOGOUT session destroyed');
                    //     Cookies.remove('UserDetail');
                    //     Cookies.remove('access_token');
                    //     sessionStorage.clear();
                    //     history.push('/');
                    //   }

                    // });

                    logOut();
                    return dispatch(loginUnSuccessfull(false));
                  } else {
                    return dispatch(loginSuccess(true, response));
                  }
                } else {
                  return dispatch(loginSuccess(true, response));
                }
              });
            }
          }

          //
          setTimeout(() => {
            //console.log(Cookies.get('sid'));
            // Cookies.set('logedIn', true);
            //  return dispatch(loginSuccess(true, response));
          }, 100);
        } else if (response.status === 203 || response.status === 204) {
          // console.log('credentials incorrect');
          // Cookies.set('logedIn', false);
          notification.error({ message: 'Incorrect Credentials' });
          return dispatch(loginUnSuccessfull(false));
        }
      });
    } catch (err) {
      // Cookies.set('logedIn', false);
      dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  const access_token = Cookies.get('access_token');
  Cookies.remove('UserDetail');
  Cookies.remove('access_token');
  Cookies.remove('sessionID');
  sessionStorage.clear();
  return async dispatch => {
    //const history = useHistory(); //for redirects in page
    try {
      dispatch(logoutBegin());
      //Cookies.remove('access_token');
      //headers from variables was containing empty token so doing this------STARTS
      const headers = {
        'Content-Type': 'application/json; charset=UTF-8',
        token: 'Bearer ' + access_token,
        device_id: uuid,
        device_type: du.platform,
        api_version: '1',
        session_id: session_id,
      };

      //headers from variables was containing empty token so doing this------ENDS
      Axios.get(domainpath + '/user/logout', { headers }).then(response => {
        console.log(response);
        if (response.status === 200) {
          Cookies.remove('UserDetail');
          Cookies.remove('access_token');
          sessionStorage.clear();
          dispatch(logoutSuccess(null)); //false
          //history.push('/');
        }
      });
    } catch (err) {
      Cookies.remove('UserDetail');
      Cookies.remove('access_token');
      sessionStorage.clear();
      dispatch(logoutSuccess(null)); //false
    }
  };
};
export {
  login,
  logOut,
  // alreadyloggedin
};
